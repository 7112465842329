import {Injectable} from "@angular/core";
import {BindingState} from "../entities/IOB/ProcessData";

@Injectable({
  providedIn: 'root'
})
export class IoBridgeProcess {
  // All flows | General States
  public readonly stateIdle: BindingState = {
    name: "stateIdle",
    translationKey: "IDLE",
    order: 0
  };
  public readonly stateDataNodeCheckReady: BindingState = {
    name: "stateDataNodeCheckReady",
    translationKey: "DATANODE_CHECK_READY",
    order: 1
  };
  public readonly stateCheckDesktopWriterReady: BindingState = {
    name: "stateCheckDesktopWriterReady",
    translationKey: "CHECK_DESKTOP_WRITER_READY",
    order: 2
  };
  public readonly stateWebclientGetInputData: BindingState = {
    name: "stateWebclientGetInputData",
    translationKey: "WEBCLIENT_GET_INPUT_DATA",
    order: 3
  };
  public readonly stateDataNodeGetInfo: BindingState = {
    name: "stateDataNodeGetInfo",
    translationKey: "DATANODE_GET_INFO",
    order: 4
  };
  // All flows | Bind - Unbind specific States
  public readonly stateDataNodeCommissionStart: BindingState = {
    name: "stateDataNodeCommissionStart",
    translationKey: "DATANODE_COMMISSION_START",
    order: 5
  };
  public readonly stateDataNodeDeCommissionStart: BindingState = {
    name: "stateDataNodeDeCommissionStart",
    translationKey: "DATANODE_DECOMMISSION_START",
    order: 5
  };
  // All flows | General States
  public readonly stateDataNodeKeyExchangeChallenge: BindingState = {
    name: "stateDataNodeKeyExchangeChallenge",
    translationKey: "DATA_NODE_MSG_0",
    order: 6
  };
  public readonly stateDataNodeKeyExchangeResponse: BindingState = {
    name: "stateDataNodeKeyExchangeResponse",
    translationKey: "DATA_NODE_MSG_1",
    order: 7
  };
  public readonly stateDataNodeGetSystemSecrets: BindingState = {
    name: "stateDataNodeGetDataNodeData",
    translationKey: "DATA_NODE_GET_DATANODE_DATA",
    order: 8
  };
  public readonly stateBleFindDevice: BindingState = {
    name: "stateWakeupDevice",
    translationKey: "BLE_WAKEUP_DEVICE",
    order: 9
  };
  public readonly stateBleScanConnect: BindingState = {
    name: "stateBleScanConnect",
    translationKey: "BLE_SCAN_CONNECT",
    order: 10
  };
  // All flows |  Bind - Unbind specific States
  public readonly stateBleBind: BindingState = {
    name: "stateBleBind",
    translationKey: "BLE_BIND",
    order: 11
  };
  public readonly stateBleUnbind: BindingState = {
    name: "stateBleUnbind",
    translationKey: "BLE_UNBIND",
    order: 11
  };
  // All flows | General States
  public readonly stateBleVerify: BindingState = {
    name: "stateBleVerify",
    translationKey: "BLE_VERIFY_AND_WAKEUP_DEVICE",
    order: 12
  };
  // All flows | Bind - Unbind specific States
  public readonly stateDataNodeCommissionCommit: BindingState = {
    name: "stateDataNodeCommissionCommit",
    translationKey: "DATA_NODE_COMMISSION_COMMIT",
    order: 13
  };
  public readonly stateDataNodeDeCommissionCommit: BindingState = {
    name: "stateDataNodeDeCommissionCommit",
    translationKey: "DATA_NODE_DECOMMISSION_COMMIT",
    order: 13
  };
  public readonly stateDataNodeCommissionRollback: BindingState = {
    name: "stateRollback",
    translationKey: "ROLLBACK",
    order: 14
  };
  public readonly stateDataNodeDeCommissionRollback: BindingState =
    this.stateDataNodeCommissionRollback;
  // All flows | Bind - Unbind specific States
  public readonly stateFinalize: BindingState = {
    name: "stateSuccess",
    translationKey: "SUCCESS",
    order: 15
  };

  public bindFlow(): BindingState[] {
    return Array.of(
      this.stateIdle,
      this.stateDataNodeCheckReady,
      this.stateCheckDesktopWriterReady,
      this.stateWebclientGetInputData,
      this.stateDataNodeGetInfo,
      this.stateDataNodeCommissionStart,
      this.stateDataNodeKeyExchangeChallenge,
      this.stateDataNodeKeyExchangeResponse,
      this.stateDataNodeGetSystemSecrets,
      this.stateBleFindDevice,
      this.stateBleScanConnect,
      this.stateBleBind,
      this.stateBleVerify,
      this.stateDataNodeCommissionCommit,
      this.stateDataNodeCommissionRollback,
      this.stateFinalize);
  }

  public unbindFlow(): BindingState[] {
    return Array.of(
      this.stateIdle,
      this.stateDataNodeCheckReady,
      this.stateCheckDesktopWriterReady,
      this.stateWebclientGetInputData,
      this.stateDataNodeGetInfo,
      this.stateDataNodeDeCommissionStart,
      this.stateDataNodeKeyExchangeChallenge,
      this.stateDataNodeKeyExchangeResponse,
      this.stateDataNodeGetSystemSecrets,
      this.stateBleFindDevice,
      this.stateBleScanConnect,
      this.stateBleUnbind,
      this.stateBleVerify,
      this.stateDataNodeDeCommissionCommit,
      this.stateDataNodeDeCommissionRollback,
      this.stateFinalize);
  }

  public getStateByName(name: string): BindingState {
    const bindProcess = this.bindFlow().filter(process => process.name.toLowerCase() == name.toLowerCase());
    if (bindProcess.length == 1) {
      return bindProcess[0];
    }
    const unBindProcess = this.unbindFlow().filter(process => process.name.toLowerCase() == name.toLowerCase());
    if (unBindProcess.length == 1) {
      return unBindProcess[0];
    }
    return this.stateIdle;
  }
}
