import {Pipe, PipeTransform} from '@angular/core';
import {ChangeDetectorValue} from "../../shared/util/change-detector/ChangeDetectorValue";

@Pipe({
  name: 'hasChangeErrors',
  pure: false
})
export class HasChangeErrorsPipe implements PipeTransform {

  transform(control: ChangeDetectorValue | ChangeDetectorValue[] | null, fieldName?: string, ...keys: string[]): boolean {

    if(control == null) {
      return false;
    }

    if(!Array.isArray(control)) {
      control = [control];
    }

    return control.map(singleControl => {
      if (!fieldName) {
        return singleControl.isValid;
      }

      if (!singleControl.isFieldTouched(fieldName)) {
        return true;
      }

      if (keys.length == 0) {
        return singleControl.isSpecificGroupValid(fieldName!);
      }
      return keys.map(key => {
        return singleControl.isSpecificValid(`${fieldName!}${key!}`);
      }).filter(valid => !valid).length == 0;
    }).filter(valid => !valid).length > 0;
  }

}
