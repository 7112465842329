import {getGlobalTimeModelEditName, getGlobalTimeModels} from "../../timeModels";
import {TimeModelDto} from "../../../entities/TimeModel/TimeModelDto";
import {ChangeDetectorModel} from "../ChangeDetectorValue";
import {EventEmitter} from "@angular/core";

export class TimeModelBasic implements ChangeDetectorModel {
  viewablePasswordFields: string[] = [];
  valueChange: EventEmitter<any> = new EventEmitter<any>();

  private readonly _validatorMaxLength64: number = 64;
  private readonly _validatorMaxLength32: number = 32

  private _uuid: string = '';
  private _timeModelNumber: number = -1;
  private _shortName: string = '';
  private _description: string = '';
  private _modelTypeId: string = '0';

  constructor() {
  }

  private shortenNameUnique(): boolean {
    const currentlyEditedTimeModelName: string | undefined = getGlobalTimeModelEditName();
    if (currentlyEditedTimeModelName != undefined && currentlyEditedTimeModelName.toLowerCase() == this._shortName.toLowerCase()) {
      return true;
    }
    return ((getGlobalTimeModels() || [] as TimeModelDto[])
      .filter(timeModel => timeModel.shortName.toLowerCase() == this._shortName.toLowerCase()).length) == 0
  }

  public validators(): Map<string, (input: any) => boolean> {
    return new Map<string, (input: any) => boolean>()
      .set('shortNameRequired', (input: any) => {
        return input._shortName.replaceAll(' ', '').length > 0;
      }).set('shortNameMax', (input: any) => {
        return input._shortName.length <= this._validatorMaxLength32;
      }).set('shortNameUnique', (input: any) => {
        return (input as TimeModelBasic).shortenNameUnique();
      }).set('descriptionMax', (input: any) => {
        return ((input as TimeModelBasic)._description || '').length <= this._validatorMaxLength64;
      });
  }

  /*
   * Getter and Setter
   */

  public set values(values: {uuid: string, timeModelNumber: number, shortName: string, description: string, modelTypeId: string}) {
    this._uuid = values.uuid;
    this._timeModelNumber = values.timeModelNumber;
    this._shortName = values.shortName;
    this._description = values.description;
    this._modelTypeId = values.modelTypeId;
  }

  set uuid(value: string) {
    this._uuid = value;
    this.valueChange.emit(true);
  }

  set timeModelNumber(value: number) {
    this._timeModelNumber = value;
    this.valueChange.emit(true);
  }

  set shortName(value: string) {
    this._shortName = value;
    this.valueChange.emit(true);
  }

  set description(value: string) {
    this._description = value;
    this.valueChange.emit(true);
  }

  set modelTypeId(value: string) {
    this._modelTypeId = value;
    this.valueChange.emit(true);
  }

  get validatorMaxLength64(): number {
    return this._validatorMaxLength64;
  }

  get validatorMaxLength32(): number {
    return this._validatorMaxLength32;
  }

  get uuid(): string {
    return this._uuid;
  }

  get timeModelNumber(): number {
    return this._timeModelNumber;
  }

  get shortName(): string {
    return this._shortName;
  }

  get description(): string {
    return this._description;
  }

  get modelTypeId(): string {
    return this._modelTypeId;
  }
}
