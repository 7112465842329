import { Pipe, PipeTransform } from '@angular/core';
import {FormatterService} from "../../services/formatter-service/formatter.service";

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {

  constructor(private formatter: FormatterService) {
  }

  transform(
    value: number | string,
    options: {format?: 'time' | 'date' | 'dateTime', replaceInvalid?: string} = {format: 'dateTime', replaceInvalid: ''}
  ): String {
    let timestamp = 0;

    switch (typeof value) {
      case 'string':
        timestamp = parseInt(value);
        break;
      case 'number':
        timestamp = value;
        break;
      case 'object':
        value = parseInt(`${value}`);
        break;
      default:
        return '';
    }

    return this.formatter.formatTimestamp(
      timestamp,
      {mode: options?.format || 'dateTime', replaceInvalid: options?.replaceInvalid}
    );
  }

}
