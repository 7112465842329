import {Component, OnInit} from '@angular/core';
import {IModalContent} from "../IModalContent";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {SubmitUtils} from "../../../../shared/util/SubmitUtils";
import {AuthenticationService} from "../../../../core/services/auth-service/auth.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {PasswordComplexityComponent} from "../../../../shared/password-complexity/password-complexity.component";
import {ApiService} from "../../../../core/services/api-service/api.service";
import {ToastService} from "../../../../shared/notification/toast/toast.service";
import {NewPasswordCdm} from "../../../../shared/util/change-detector/models/new-password.cdm";
import {ChangeDetectorValue} from "../../../../shared/util/change-detector/ChangeDetectorValue";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, IModalContent {

  loading: boolean = false;
  changePasswordValue: ChangeDetectorValue;

  constructor(
    private notification: ToastService,
    private passwordComplexity: PasswordComplexityComponent,
    private authService: AuthenticationService,
    private ngbModal: NgbActiveModal) {
    this.changePasswordValue = new ChangeDetectorValue(new NewPasswordCdm());
  }

  async ngOnInit(): Promise<void> {
    const passwordComplexityRegex: string = await this.passwordComplexity.complexityRegexPattern();
    this.changePasswordValue = new ChangeDetectorValue(new NewPasswordCdm(passwordComplexityRegex));
  }


  async onSubmit() {
    if (SubmitUtils.reflectCheck(this.notification, true, this.changePasswordValue.isValid)) {
      return;
    }
    this.loading = true;

    this.authService.changePassword(
      this.changePasswordValue.value.oldPassword,
      this.changePasswordValue.value.newPassword,
      this.changePasswordValue.value.confirmPassword)
      .then(success => {
        if (success) {
          this.notification.showSuccess('NOTIFICATION.TOAST.SUCCESS.UPDATE');
          this.onClose();
        }
      })
      .finally(() => this.loading = false);
  }

  onClose() {
    this.ngbModal.close()
  }
}
