import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IoBridgeService } from 'src/app/core/services/io-bridge-service/io-bridge.service';
import { CommissionManager } from 'src/app/core/services/auth-service/support-services/CommissionManager';
import { ModalCloseCause } from 'src/app/core/enums/modalCloseCause';
import { BindingProcessError } from 'src/app/shared/entities/IOB/BindingProcessError';
import { debounceTime } from 'rxjs';
import { CommissioningComponent } from 'src/app/shared/commissioning/commissioning.component';
import { ItemAction } from 'src/app/shared/models/Actions';
import { IoBridgeFlow } from 'src/app/core/enums/ioBridgeFlow';

@Component({
  selector: 'app-locking-device-binding',
  templateUrl: './locking-device-binding.component.html',
  styleUrl: './locking-device-binding.component.scss'
})
export class LockingDeviceBindingComponent implements OnInit, OnDestroy {
  @ViewChild(CommissioningComponent) Commissioning!: CommissioningComponent;

  private _commissionManager: CommissionManager = CommissionManager.getInstance(IoBridgeFlow.DEVICE_BIND);

  isLoading: boolean = false;
  globalBindingErrorProcess: BindingProcessError;

  providePin: string = '';
  provideSN: string = '';
  processAndPollingRunning: boolean = false;

  headerText: string = 'PROGRAMMING.MODAL.LOCKING_DEVICE.ADD';
  buttonText: string = 'PROGRAMMING.MODAL.BUTTON.CLOSE_LIST';

  addRemoveButton: ItemAction = new ItemAction (
    'PROGRAMMING.MODAL.LOCKING_DEVICE.SEND_AND_BIND',
    (): void => {this.startProcessIOBridge()},
    false,
    'btn btn-outline-primary',
    ''
  )

  constructor(
    private ioBridgeService: IoBridgeService,
  ){
    this.globalBindingErrorProcess = this.commissionManager.bindingProcessError;
    this.commissionManager.bindingProcessErrorObservable.pipe(debounceTime(500)).subscribe({
      next: value => this.globalBindingErrorProcess = value
    });
  }

  ngOnInit(): void {
    if (!this.globalBindingErrorProcess.blockTillReset) {
      this.isLoading = true;
      this.ioBridgeService.iobConnectionUpdater().then(() => {
        this.isLoading = false;
      });
    }
  }

  ngOnDestroy() {
    if (this.commissionManager.bindingProcessState.order == 0 && !this.globalBindingErrorProcess.blockTillReset) {
      this.commissionManager.reset();
    }
    this.ioBridgeService.iobConnectionUpdater();
  }

  async startProcessIOBridge() {
    this.Commissioning.startButtonClicked = true;
    this.bind()
    this.Commissioning.startButtonClicked = false;
  }

  private async bind() {
    await this.Commissioning.prepare();
    this.Commissioning.pollFetch().catch(() => this.processAndPollingRunning = false);
    const bind: Response = await this.ioBridgeService.fetchBindingDevice(this.commissionManager.bindingProcess.pin, this.provideSN);
    if (bind.ok) {
      let body: any = undefined;
      if (bind.status != 204 && bind.bodyUsed) {
        body = await bind.json();
      }
      await this.Commissioning.finishSuccessAndClose((body == undefined || body.uuid == undefined) ? (ModalCloseCause.SUCCESS || 3) : body);
    } else {
      await this.Commissioning.finishError(bind);
    }
  }


  //

  get commissionManager(): CommissionManager {
    return this._commissionManager;
  }

  get serialNumberInputEnabled(): boolean {
    return (this.Commissioning ? (!this.Commissioning.startButtonClicked && !this.isLoading) : false)
  }

  get pinInputEnabled(): boolean {
    return !((this.providePin.length != 6) || (this.provideSN.length < 5) || this.commissionManager.bindingProcessState.order! != 0) && !this.commissionManager.errorIsPresent;
  }

}
