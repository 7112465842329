import {Pipe, PipeTransform} from '@angular/core';
import {IoBridgeStatus} from "../../enums/ioBridgeStatus";

@Pipe({
  name: 'iobIndicator'
})
export class IoBridgeIndicatorPipe implements PipeTransform {

  private readonly dtwError: string = "assets/ces/iob/error.svg";
  private readonly dtwWarning: string = "assets/ces/iob/warning.svg";
  private readonly dtwOk: string = "assets/ces/iob/ok.svg";

  transform(type: string,
            options: {iob?: IoBridgeStatus, dtw?: IoBridgeStatus, updater?: any}): string {
    switch (type) {
      case 'image':
        return this.image(options.iob!, options.dtw!);
      case 'status-key':
        if (options.dtw) {
          return this.status(options.dtw!);
        } else if (options.iob) {
          return this.status(options.iob!);
        }
        return '';
      case 'color':
        if (options.dtw) {
          return this.color(options.dtw!);
        } else if (options.iob) {
          return this.color(options.iob!);
        }
        return '';
      default:
        return '';
    }
  }

  private status(status: IoBridgeStatus): string {
    switch (status) {
      case IoBridgeStatus.CONNECTED:
        return 'PROGRAMMING.CONNECTION.CONNECTED';
      case IoBridgeStatus.UPDATE_REQUIRED:
        return 'PROGRAMMING.CONNECTION.UPDATE_REQUIRED';
      default:
        return 'PROGRAMMING.CONNECTION.DISCONNECTED';
    }
  }

  private color(status: IoBridgeStatus): string {
    switch (status) {
      case IoBridgeStatus.CONNECTED:
        return '#00A094';
      case IoBridgeStatus.UPDATE_REQUIRED:
        return '#F7CC53';
      default:
        return '#802120';
    }
  }

  private image(iob: IoBridgeStatus, dtw: IoBridgeStatus): string {
    switch (iob) {
      case IoBridgeStatus.CONNECTED:
        switch (dtw) {
          case IoBridgeStatus.CONNECTED:
            return `${this.dtwOk}`;
          default:
            return `${this.dtwWarning}`;
        }
      case IoBridgeStatus.UPDATE_REQUIRED:
        return `${this.dtwWarning}`;
      default:
        return `${this.dtwError}`;
    }
  }

}
