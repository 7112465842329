import {Pipe} from '@angular/core';
import {TranslatePipe} from "@ngx-translate/core";

@Pipe({
  name: 'iob'
})
export class IoBridgePipe extends TranslatePipe {
  private readonly translationTextError: string = 'PROGRAMMING.ERROR.';

  override transform(err: string, state: string, options: {errorIsPresent: boolean, code?: number | string}): string {
    if (options.errorIsPresent) {
      return super.transform(this.getErrorText(err))
        .replace('{{XXX}}', `${options.code == undefined || options.code == 0 ? '' : options.code}`);
    }
    return super.transform(`PROGRAMMING.STATES.${state}`, options);
  }

  private getErrorText(value: string): string {
    const errVal = (value.replace(this.translationTextError, ''));
    switch (errVal.toUpperCase()) {
      case 'ErrGeneral'.toUpperCase():
      case 'ErrGeneralWorkflowNotValid'.toUpperCase():
      case 'ErrGeneralParamUuidNotFound'.toUpperCase():
      case 'ErrGeneralDecodeSystemSecrets'.toUpperCase():
      case 'ErrGeneralGetSystemSecrets'.toUpperCase():
      case 'ErrDataNodeNotReady'.toUpperCase():
      case 'ErrDataNodeApiKeyNotValid'.toUpperCase():
      case 'ErrDataNodeTokenNotValid'.toUpperCase():
      case 'ErrDataNodeUrlNotValid'.toUpperCase():
      case 'ErrDeviceAlreadyUnbounded'.toUpperCase():
      case 'ErrDeviceStillBounded'.toUpperCase():
      case 'ErrDeviceStillUnbounded'.toUpperCase():
      case 'ErrInvalidOperatingKey'.toUpperCase():
      case 'ErrInvalidMediaAppKey'.toUpperCase():
      case 'ErrSystemBindStart'.toUpperCase():
      case 'ErrSystemBindCreateResponse'.toUpperCase():
      case 'ErrSystemBindResponse'.toUpperCase():
      case 'ErrSystemBindDecodeChallenge'.toUpperCase():
      case 'ErrSystemBindAccept'.toUpperCase():
      case 'ErrSystemBindSessionNonce'.toUpperCase():
      case 'ErrSystemBindSessionKey'.toUpperCase():
      case 'ErrKeyExchange'.toUpperCase():
      case 'ErrGeneralKeyExchangePublicKey'.toUpperCase():
      case 'ErrGeneralKeyExchangePasswordHash'.toUpperCase():
      case 'ErrGeneralKeyExchangeChallenge'.toUpperCase():
      case 'ErrGeneralKeyExchangeCreateResponse'.toUpperCase():
      case 'ErrGeneralKeyExchangeDecodeChallenge'.toUpperCase():
      case 'ErrGeneralKeyExchangeSessionKey'.toUpperCase():
      case 'ErrGeneralKeyExchangeNonce'.toUpperCase():
      case 'ErrGeneralDeCommissionStartFail'.toUpperCase():
      case 'ErrGeneralDeCommissionStartParsing'.toUpperCase():
      case 'ErrGeneralDeCommissionRollbackFail'.toUpperCase():
      case 'ErrGeneralDeCommissionCommitFail'.toUpperCase():
      case 'ErrGeneralCommissionStartFail'.toUpperCase():
      case 'ErrGeneralCommissionStartParsing'.toUpperCase():
      case 'ErrGeneralCommissionRollbackFail'.toUpperCase():
      case 'ErrGeneralCommissionCommitFail'.toUpperCase():
      case 'ErrBindInputValidate'.toUpperCase():
      case 'ErrGeneralBindInputParsing'.toUpperCase():
      case 'ErrGeneralCommissionCommitParsing'.toUpperCase():
        return `${this.translationTextError}ErrGeneral`;

      case 'ErrDeviceSerialNumberInUse'.toUpperCase():
        return `${this.translationTextError}ErrService`;

      case 'ErrDeviceAlreadyBounded'.toUpperCase():
      case 'ErrSystemBindNotSupportedByDevice'.toUpperCase():
      case 'ErrFindDevicesTimeout'.toUpperCase():
      case 'ErrDtwNotReady'.toUpperCase():
      case 'ErrSystemBindNotSupportedBySystem'.toUpperCase():
      case 'ErrSerialNumberNotValid'.toUpperCase():
      case 'ErrKeyExchangeWrongPasswordHash'.toUpperCase():
        return `${this.translationTextError}${errVal}`;

      default:
        return `${this.translationTextError}ErrGeneral`;
    }
  }
}
