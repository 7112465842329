import {Component, OnInit} from '@angular/core';

import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../core/services/api-service/api.service";
import {TranslateService} from "@ngx-translate/core";
import {ItemAction, ItemDeleteAction, ItemSaveAction, ListEditAction, ListViewAction} from "../../shared/models/Actions";
import {ListActionEvent} from "../../shared/models/ListActionEvent";
import {ItemActionEvent} from "../../shared/models/ItemActionEvent";
import {ChangeDetectorForm} from "../../shared/util/change-detector/ChangeDetectorForm";
import {ItemListFilter, ItemListItem, ItemListMapper} from "../../shared/item-list/ItemListItem";
import {PendingChangesBlocker} from "../../core/guards/pending-changes-view.guard";
import {FormValidator} from "../../shared/util/FormValidator";
import {FormatterService} from "../../core/services/formatter-service/formatter.service";
import {ItemManager} from "../../shared/item-list/ItemManager";
import {SubmitUtils} from "../../shared/util/SubmitUtils";
import {SessionManager} from "../../core/services/auth-service/support-services/SessionManager";
import {debounceTime, firstValueFrom, take} from "rxjs";
import {LockingMediaDto} from "../../shared/entities/LockingMediaDto";
import {MEDIA_IMPLEMENTATION, MEDIA_STATE, MEDIA_TYPE} from "../../shared/lookup/media.lookup";
import {ToastService} from "../../shared/notification/toast/toast.service";
import {ChangeDetectorValue} from "../../shared/util/change-detector/ChangeDetectorValue";
import { CommissionManager } from '../../core/services/auth-service/support-services/CommissionManager';
import { IoBridgeStatus } from 'src/app/core/enums/ioBridgeStatus';
import { environment } from 'src/environments/environment';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalCloseCause } from 'src/app/core/enums/modalCloseCause';
import { LockingMediaBindingComponent } from './modals/locking-media-binding/locking-media-binding.component';
import { LockingMediaUnbindingComponent } from './modals/locking-media-unbinding/locking-media-unbinding.component';
import { LockingMediaRebindingComponent } from './modals/locking-media-rebinding/locking-media-rebinding.component';

import {LockingMediaCdm} from "../../shared/util/change-detector/models/locking-media.cdm";
import { ModalContainerContent } from 'src/app/shared/notification/modal/modal-container.class';
import { ModalService } from 'src/app/shared/notification/modal/modal.service';
import { IoBridgeFlow } from 'src/app/core/enums/ioBridgeFlow';

@Component({
  selector: 'app-locking-media',
  templateUrl: './locking-media.component.html',
  styleUrls: ['./locking-media.component.scss'],
})
export class LockingMediaComponent implements OnInit, ItemListMapper<LockingMediaDto>, PendingChangesBlocker {
  private commissionManager: CommissionManager;

  handoutDate: ChangeDetectorValue = new ChangeDetectorValue({year: 2024, month: 9, day: 9});
  returnDate: ChangeDetectorValue = new ChangeDetectorValue({year: 2024, month: 9, day: 9});

  _isLoading: boolean = false;
  get isLoading():boolean {
    return this._isLoading;
  }
  itemManager: ItemManager<LockingMediaDto>;
  selectedItem?: LockingMediaDto;
  media: ChangeDetectorValue = new ChangeDetectorValue(undefined);
  itemSelected = false;

  saveAction = new ItemSaveAction();
  deleteAction = new ItemDeleteAction();

  licenseTypeId: number = 1;
  licenseIsValidForBusiness: boolean = true;

  accessReadonly = true;

  getAccessReadonly(): boolean {
    return this.apiService.lockingMedia.isReadonly() || (!this.licenseIsValidForBusiness && this.licenseTypeId > 1);
  }

  showArticleNumber = false;

  typeaheadIndex = new Set<string>();

  get searchEntries() {
    return [...this.typeaheadIndex.values()];
  }

  searchQuery: string = '';
  searchFilter = new ItemListFilter<LockingMediaDto>((item) => {
    return String(item.displayUid || '')
      .toLowerCase()
      .includes(this.searchQuery.toLowerCase());
  });

  canAddOrRemoveMedia = false

  ioBridgeConnected: boolean = false;

  generateUnbindAction: ItemAction = new ItemAction(
    "PROGRAMMING.MODAL.LOCKING_MEDIA.REMOVE",
    (): void => {
    },
    false,
    "btn-outline-danger",
    "mdi mdi-door-open");

  generateReplaceAction: ItemAction = new ItemAction(
    "PROGRAMMING.MODAL.LOCKING_MEDIA.REPAIR",
    (): void => {
    },
    false,
    "btn-outline-secondary",
    "mdi mdi-auto-fix");

  constructor(
    private apiService: ApiService,
    private translate: TranslateService,
    private formatter: FormatterService,
    private notification: ToastService,
    private modalService: NgbModal,
    private alertService: ModalService,
  ) {
    this.itemManager = new ItemManager<LockingMediaDto>(this, notification, this.sortDisplayedItems);
    this.commissionManager = CommissionManager.getInstance();
  }

  async ngOnInit() {
    const sessionManager: SessionManager = SessionManager.getInstance()
    this.canAddOrRemoveMedia = !sessionManager.isLicenseExpired && sessionManager.isLicenseBusiness && sessionManager.isAdminOrHigher;
    this._isLoading = true;
    this.accessReadonly = this.getAccessReadonly();

    this.apiService.lockingMedia.getAll().then(observer => {
      observer.pipe(take(1)).subscribe({
        next: items => {
          this.itemManager.setItems(items);
          this.itemManager.forEach((value) => {
            this.typeaheadIndex.add(value.item.displayUid);
          });
          this._isLoading = false;
        },
        error: () => {
          this._isLoading = false;
        }
      });
    });

    this.licenseTypeId = SessionManager.getInstance().isLicenseBusiness ? 2 : 1;
    this.licenseIsValidForBusiness = this.licenseTypeId == 2 && !SessionManager.getInstance().isLicenseExpired;

    this.ioBridgeConnected = this.commissionManager.bindingProcess.ioBridgeStatus == IoBridgeStatus.CONNECTED;
    this.commissionManager.bindingProcessObservable.pipe(debounceTime(500)).subscribe({
      next: data => {
        this.ioBridgeConnected = data.ioBridgeStatus == IoBridgeStatus.CONNECTED;
      },
      error: () => {
        this.ioBridgeConnected = false;
      }
    });
  }

  async onSelectEvent(actionEvent: ListActionEvent<LockingMediaDto>) {
    if (actionEvent == undefined) return;

    await this.onSelect(actionEvent.item);

    if (SessionManager.getInstance().isAdminOrHigher && CommissionManager.getInstance().bindingProcess.ioBridgeStatus == IoBridgeStatus.CONNECTED) {
      if (environment.featureFlags.mediaUnbindEnabled) {
        if(actionEvent.item.mediaNumber < 100000){
            actionEvent.addItemAction(this.generateUnbindAction);
            // actionEvent.addItemAction(this.generateReplaceAction);
        }
      }
    }

    if (actionEvent.action instanceof ListEditAction) {
      if (this.apiService.lockingMedia.canDeleteLockingMedia(actionEvent.item)) {
        actionEvent.addItemAction(this.deleteAction);
      }

      this.saveAction.disabled = true;
      actionEvent.addItemAction(this.saveAction);
    }
  }

  private async onSelect(item: LockingMediaDto) {
    this.selectedItem = item;
    let changeDetectorItem: LockingMediaCdm = new LockingMediaCdm();
    changeDetectorItem.values = item;
    this.media = new ChangeDetectorValue(changeDetectorItem, () => {
      this.saveAction.disabled = !(this.media.hasChanges && this.media.isValid);
    }, changeDetectorItem.validators());

    this.showArticleNumber = this.apiService.lockingMedia.getIsArticleNumberVisible(item);

  }

  get implementationType(): string {
    return this.translate.instant(`LOCKING_MEDIA.IMPLEMENTATION.${[...MEDIA_IMPLEMENTATION.filter(value => value.id == this.media.value?.implementationId), MEDIA_IMPLEMENTATION[0]][0].value.toUpperCase()}`);
  }

  get lockingMediaType(): string {
    return this.translate.instant(`LOCKING_MEDIA.TYPE.${[...MEDIA_TYPE.filter(value => value.id == this.media.value?.typeId), MEDIA_TYPE[0]][0].value.toUpperCase()}`);
  }

  mapToItemList(item: LockingMediaDto): ItemListItem<LockingMediaDto> {
    return new ItemListItem(item.uuid, item.displayUid, item) //TODO display mechanical ID since UID won't be displayed on self scanned media?
      .addInfo(this.formatter.formatArray(item.assignedUsers || [], this.translate.instant('LOCKING_MEDIA.ITEM_LIST.NO_USERS')))
      .setImage(`assets/ces/locking-media/${this.apiService.lockingMedia.getImageNameForMediaType(item.implementationId)}.svg`)
      .addAction(this.accessReadonly ? new ListViewAction() : new ListEditAction())
      .setColor(item.stateId == 3 || item.stateId == 4 ? '#404040' : undefined);
  }

  hasPendingChanges(): boolean {
    return this.media.hasChanges;
  }

  async onEditEvent(actionEvent: ItemActionEvent<LockingMediaDto>) {
    if (actionEvent == undefined) return;

    if (actionEvent.action instanceof ItemSaveAction) {
      await this.onSubmit(actionEvent.returnToList);
    }
    if (actionEvent.action instanceof ItemDeleteAction) {
      await this.onDelete(actionEvent.returnToList);
    }

    if (actionEvent.action == this.generateUnbindAction) {
        await this.modalComponent(actionEvent.returnToList, IoBridgeFlow.MEDIA_UNBIND)
    } // else if (actionEvent.action == this.generateReplaceAction) {
    //     await this.modalComponent(actionEvent.returnToList, IoBridgeFlow.MEDIA_REBIND)
    // }
  }

  resetForms() {
    this.media.reset();
  }

  async onSubmit(returnToList: () => void) {
    // stop here if form is invalid
    if (SubmitUtils.reflectCheck(this.notification, true, this.media.isValid)) {
      return;
    }

    if(this.media.value.stateId == 3 || this.media.value.stateId == 4){
      const modalContent: ModalContainerContent = new ModalContainerContent(
        'Achtung',
        `Medien mit dem Status ${this.media.value.stateId == 3 ? 'DEFEKT':'VERLOREN'} können nicht mehr bearbeitet werden`
      );
      if (await this.alertService.openModal(modalContent)) {
        (await this.apiService.lockingMedia.update(this.media.value.toLockingMediaDto)).subscribe({
          next: value => {
            this.itemManager.updateItem(value);
            this.resetForms();
            returnToList();
          }
        });
      }
    }else{
      (await this.apiService.lockingMedia.update(this.media.value.toLockingMediaDto)).subscribe({
        next: value => {
          this.itemManager.updateItem(value);
          this.resetForms();
          returnToList();
        }
      });
    }
  }

  // Für Meilenstein 2
  private async modalComponent(returnToList: () => void, flow: IoBridgeFlow) {
    this.commissionManager = CommissionManager.getInstance(flow).init(this.selectedItem)
    this.modalService.open(flow == IoBridgeFlow.MEDIA_UNBIND ? LockingMediaUnbindingComponent : LockingMediaRebindingComponent).closed.pipe(debounceTime(500)).subscribe(async closeReason => {
        console.log(typeof closeReason, closeReason)
        // switch (typeof closeReason) {
        //     case "number":
        //     case "object":
        //         if (closeReason == (ModalCloseCause.SUCCESS || 3)) {
        //             this.removeCurrentItem();
        //             // if (CommissionManager.getInstance().bindingProcess.flow == IoBridgeFlow.MEDIA_REBIND) {
        //             //   await this.ngOnInit();
        //             // }
        //             returnToList();
        //             this.onReturnEvent();
        //         }
        //         break;
        //     case "string":
        //         let item = this.removeCurrentItem();
        //         item.serialNumber = closeReason;
        //         this.selectedItem = item as LockingDeviceDto;
        //         this.itemManager.addItems(item as LockingDeviceDto);
        //         const changesWereMade: boolean = this.lockingDeviceForm.changed || this.lockingDeviceParamsForm.changeDetectorChanges.hasChanges;
        //         this.lockingDeviceForm.lockingDeviceForm.patchValue({serialNumber: item.serialNumber});
        //         if (!changesWereMade) {
        //             this.lockingDeviceForm.lockingDeviceForm.markAsUntouched();
        //             this.lockingDeviceForm.changeDetector.refresh();
        //         }
        //         break;
        // }
    });
  }

  private removeCurrentItem(): any {
    let selectedItemPuffer: any = this.selectedItem!;
    this.itemManager.removeItems(this.selectedItem!);
    return selectedItemPuffer
  }

  onSearch(search: string) {
    this.searchQuery = search;
    this.searchFilter.triggerItemUpdate();
  }

  private async onDelete(returnToList: () => void) {
    if (await firstValueFrom(await this.apiService.lockingMedia.delete(this.selectedItem!.uuid))) {
      this.itemManager.removeItemsById(this.selectedItem!.uuid);
      this.resetForms();
      returnToList();
    }
  }

  onAddMedia() {
    var addedMedia = false;

    this.commissionManager = CommissionManager.getInstance(IoBridgeFlow.MEDIA_BIND).init();
    const commissioningModal: NgbModalRef = this.modalService.open(LockingMediaBindingComponent);

    commissioningModal.closed.pipe(debounceTime(500)).subscribe(async closeReason => {
      if (typeof closeReason === "object" && closeReason.uuid != undefined) {
        this.itemManager.addItems(closeReason as LockingMediaDto);
        this.itemManager.forEach((value => {
          this.typeaheadIndex.add(value.name)
        }));

      } else if (typeof closeReason === "number" && closeReason == ModalCloseCause.SUCCESS) {
        // Uuid was not returned. Therefore, a fallback with a complete reload
        await this.reload(addedMedia, true)
      } else {
        await this.reload(addedMedia)
      }
    });

    commissioningModal.dismissed.subscribe(async () => {
        await this.reload(addedMedia)
    })

    commissioningModal.componentInstance.getAddedMedia().subscribe((res: boolean) => addedMedia = res)
  }

  async reload(addedMedia: boolean, skipCheck?: boolean) {
    if(addedMedia || skipCheck){
        await this.ngOnInit();
    }
  }
  
  sortDisplayedItems(items: Map<string, ItemListItem<LockingMediaDto>>){
    var itemsArray = Array.from(items).sort((a,b) => a[1].item.mediaNumber - b[1].item.mediaNumber);

    var itemsState3 = itemsArray.filter(i => i[1].item.stateId == 3);
    var itemsState4 = itemsArray.filter(i => i[1].item.stateId == 4);
    var itemsStateO = itemsArray.filter(i => i[1].item.stateId != 3 && i[1].item.stateId != 4);

    items = new Map(itemsStateO.concat(itemsState3).concat(itemsState4))
    return items
  }

  get stateTranslationText():string {
    return `LOCKING_MEDIA.STATE.${[...MEDIA_STATE.filter(value => `${this.media.value.stateId}` == `${value.id}`), MEDIA_STATE[0]][0].value.toUpperCase()}`;
  }

  protected readonly MEDIA_STATE = MEDIA_STATE;
  protected readonly environment = environment;
}
