import {EventEmitter} from "@angular/core";
import {LockingDeviceParamsDto} from "../../../entities/locking-device/LockingDeviceParamsDto";
import {ChangeDetectorModel, ChangeDetectorValue} from "../ChangeDetectorValue";
import {FormatterService} from "../../../../core/services/formatter-service/formatter.service";

export class LockingDeviceParamsCdm implements ChangeDetectorModel, LockingDeviceParamsDto {
  viewablePasswordFields: string[] = [];

  private _uuid: string | undefined;
  private _openingDuration: number;
  private _beeper: boolean;
  private _officeFunction: boolean;
  private _officeTimeout: number | undefined | { hour: number; minute: number; };
  private _openTimeModelUuid: string | undefined;
  private _closeTimeModelUuid: string | undefined;
  private _variantId: number;
  private _loggingId: number | undefined;
  private _paramsGroupId: number | undefined;
  private _paramsGroupName: string | undefined;

  constructor(uuid: string | undefined,
              openingDuration: number,
              beeper: boolean,
              officeFunction: boolean,
              officeTimeout: number | undefined | { hour: number; minute: number; },
              openTimeModelUuid: string | undefined,
              closeTimeModelUuid: string | undefined,
              variantId: number,
              loggingId: number | undefined,
              paramsGroupId: number | undefined,
              paramsGroupName: string | undefined,
  ) {
    this.valueChange = new EventEmitter();
    this._uuid = uuid;
    this._openingDuration = openingDuration;
    this._beeper = beeper;
    this._officeFunction = officeFunction;
    this._officeTimeout = officeTimeout;
    this._openTimeModelUuid = openTimeModelUuid;
    this._closeTimeModelUuid = closeTimeModelUuid;
    this._variantId = variantId;
    this._loggingId = loggingId;
    this._paramsGroupId = paramsGroupId;
    this._paramsGroupName = paramsGroupName;
  }

  static fromLockingDeviceParamsDto(dto: LockingDeviceParamsDto): LockingDeviceParamsCdm {
    return new LockingDeviceParamsCdm(
      dto.uuid,
      dto.openingDuration,
      dto.beeper,
      dto.officeFunction,
      dto.officeTimeout,
      dto.openTimeModelUuid || '0',
      dto.closeTimeModelUuid || '0',
      dto.variantId,
      dto.loggingId,
      dto.paramsGroupId,
      dto.paramsGroupName,
    )
  }

  getLockingDeviceParamsDto(): LockingDeviceParamsDto {
    let lockingDeviceParamsDto:LockingDeviceParamsDto = new LockingDeviceParamsDto(
      this.uuid,
      this.openingDuration,
      this.beeper,
      this.officeFunction,
      this.officeTimeout,
      this.openTimeModelUuid == '0' ? undefined : this.openTimeModelUuid,
      this.closeTimeModelUuid == '0' ? undefined : this.closeTimeModelUuid,
      this.variantId,
      this.loggingId,
      this.paramsGroupId,
      this.paramsGroupName,
    );

    if (typeof lockingDeviceParamsDto.officeTimeout !== 'undefined' && typeof lockingDeviceParamsDto.officeTimeout !== 'number') {
      lockingDeviceParamsDto.officeTimeout = new FormatterService().formatFromNgbTimePicker(lockingDeviceParamsDto.officeTimeout);
    }

    return lockingDeviceParamsDto;
  }

  get uuid(): string | undefined {
    return this._uuid;
  }

  set uuid(value: string | undefined) {
    this._uuid = value;
    this.valueChange.emit(true);
  }

  get openingDuration(): number {
    return this._openingDuration;
  }

  set openingDuration(value: number) {
    this._openingDuration = value;
    this.valueChange.emit(true);
  }

  get beeper(): boolean {
    return this._beeper;
  }

  set beeper(value: boolean) {
    this._beeper = value;
    this.valueChange.emit(true);
  }

  get officeFunction(): boolean {
    return this._officeFunction;
  }

  set officeFunction(value: boolean) {
    this._officeFunction = value;
    this.valueChange.emit(true);
  }

  get officeTimeout(): number | { hour: number; minute: number } | undefined {
    return this._officeTimeout;
  }

  set officeTimeout(value: number | { hour: number; minute: number } | undefined) {
    this._officeTimeout = value;
    this.valueChange.emit(true);
  }

  get openTimeModelUuid(): string | undefined {
    return this._openTimeModelUuid;
  }

  set openTimeModelUuid(value: string | undefined) {
    this._openTimeModelUuid = value;
    this.valueChange.emit(true);
  }

  get closeTimeModelUuid(): string | undefined {
    return this._closeTimeModelUuid;
  }

  set closeTimeModelUuid(value: string | undefined) {
    this._closeTimeModelUuid = value;
    this.valueChange.emit(true);
  }

  get variantId(): number {
    return this._variantId;
  }

  set variantId(value: number) {
    this._variantId = value;
    this.valueChange.emit(true);
  }

  get loggingId(): number | undefined {
    return this._loggingId;
  }

  set loggingId(value: number | undefined) {
    this._loggingId = value;
    this.valueChange.emit(true);
  }

  get paramsGroupId(): number | undefined {
    return this._paramsGroupId;
  }

  set paramsGroupId(value: number | undefined) {
    this._paramsGroupId = value;
    this.valueChange.emit(true);
  }

  get paramsGroupName(): string | undefined {
    return this._paramsGroupName;
  }

  set paramsGroupName(value: string | undefined) {
    this._paramsGroupName = value;
    this.valueChange.emit(true);
  }

  valueChange: EventEmitter<any>;

  validators(): Map<string, (input: any) => boolean> {
    return ChangeDetectorValue.mergeValidatorMaps(
      ChangeDetectorValue.createNumberValidatorMinMaxRequired('openingDuration', 3, 180),
      new Map<string, (input: any) => boolean>()
        .set('openTimeModelUuidValid', (input: any) => {
          const uuid: string = input.openTimeModelUuid || '';
          return uuid != input.closeTimeModelUuid || (`${uuid}` == '0' || uuid == '');
        }),
      new Map<string, (input: any) => boolean>()
        .set('closeTimeModelUuidValid', (input: any) => {
          const uuid: string = input.closeTimeModelUuid || '';
          return input.openTimeModelUuid != uuid || (`${uuid}` == '0' || uuid == '');
        }),
      new Map<string, (input: any) => boolean>()
        .set('officeTimeValid', (input: any) => {
          return !(input.hour == 0 && input.minute == 0 && input.officeFunction == true);
        })
    );
  }
  set values(values: any) {
    if (values && typeof values == 'object') {
      for (let key in values) {
        const newVal = this.hasProp(values, key) ? values[key] : this.hasProp(this, `_${key}`) ? values[`_${key}`] : undefined;
        //@ts-ignore
        this.hasProp(this, key) ? this[key] = newVal : this.hasProp(this, `_${key}`) ? this[`_${key}`] = newVal : () => {};
      }
    }
  }

  private hasProp(thisArg: any, args_0: string): boolean {
    return Object.prototype.hasOwnProperty.call(thisArg, args_0);
  }
}
