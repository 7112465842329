<form>
  <div class="row row-cols-lg-6 g-4">

    <div class="col-lg-6">
      <div class="card h-100 shadow-none">
        <h6 class="card-header">{{ "SYSTEM.SECTION.DATA" | translate }}</h6>
        <div class="card-body pb-0">

          <div class="form-floating form-floating-custom mb-3">
            <input readonly type="text" class="form-control" id="systemId"
                   data-testid="overview-form-systemId"
                   [value]="system.value.systemId" placeholder="-">
            <label for="systemId">{{ "SYSTEM.FIELD.SYSTEM_NUMBER.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="id"></i>
            </div>
          </div>

          <div class="form-floating form-floating-custom mb-3">
            <input [readonly]="accessReadonly" type="text" class="form-control" id="systemName" name="systemName"
                   data-testid="overview-form-systemName"
                   [(ngModel)]="system.value.clientName"
                   [ngClass]="{'form-control': true, 'invalid-feedback-ngd': !system.isSpecificGroupValid('clientName'), 'pending-changes': system.isSpecificGroupValid('clientName') && system.hasSpecificChanges('clientName') }"
                    placeholder="-">
            <label for="systemName">{{ "SYSTEM.FIELD.SYSTEM_NAME.TEXT" | translate }}*</label>
            <div class="form-floating-icon">
              <i icon="name"></i>
            </div>
            <div *ngIf="!system.isSpecificGroupValid('clientName')" class="invalid-feedback">
              @if(!system.isSpecificValid('clientNameRequired')) {
                <div> {{ "SYSTEM.FIELD.SYSTEM_NAME.REQUIRED" | translate }} </div>
              } @else if(!system.isSpecificValid('clientNameMin') && false) {
                <div> {{ "SYSTEM.FIELD.SYSTEM_NAME.INVALID" | translate }} </div>
              } @else if(!system.isSpecificValid('clientNameMax')) {
                <div>{{ "NOTIFICATION.TOAST.CES_ERROR.cesErrStructTagBadMaxLength" | translate | errorTranslate: ("SYSTEM.FIELD.SYSTEM_NAME.TEXT" | translate) : ('' + validatorMaxLength64) }}
              </div>
              }
            </div>
          </div>

          <div class="form-floating form-floating-custom mb-3">
            <timezone-picker [(currentValue)]="timezoneId" [readonly]="accessReadonly"></timezone-picker>
          </div>

        </div>
      </div>

      <div class="card h-100 shadow-none">
        <h6 class="card-header">{{ "SYSTEM.SECTION.LICENSE" | translate }}</h6>
        <div class="card-body pb-0">

          <div class="form-floating form-floating-custom mb-3">
            <input readonly type="text" class="form-control"
                   data-testid="overview-form-licenseType" id="licenseType" [value]="licenseTypeId">
            <label for="licenseType">{{ "SYSTEM.FIELD.LICENSE_TYPE.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="license"></i>
            </div>
          </div>

          <div class="form-floating form-floating-custom" [ngClass]="{'mb-3': licenseExpiration.year != 0}">
            <div id="licenseIdCount" readonly class="form-control h-100 d-flex flex-column gab-1"
                 data-testid="overview-form-licenseIdCount">
              <div>
                {{ parseInt(system.value.idCount) }} / {{ parseInt(system.value.licenseIdCount) }}
              </div>
              <ngb-progressbar class="w-100 mb-1 border-primary" height="0.3rem"
                               [type]="progressbarClass"
                               [value]="parseInt(system.value.idCount)" [max]="parseInt(system.value.licenseIdCount)">
              </ngb-progressbar>
            </div>
            <label for="licenseIdCount">{{ "SYSTEM.FIELD.COUNT_IDS.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="info"></i>
            </div>
          </div>

          <date-time-picker *ngIf="licenseExpiration.year != 0"
                            [styleClasses]="['mb-3']"
                            titleText="SYSTEM.FIELD.LICENSE_EXPIRATION.TEXT"
                            style="flex-grow: 1"
                            [readonly]="true"
                            [(dateValue)]="licenseExpiration">
          </date-time-picker>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="card h-100 shadow-none">
        <h6 class="card-header">{{ "SYSTEM.SECTION.INFORMATION" | translate }}</h6>
        <div class="card-body pb-0">

          <div class="form-floating form-floating-custom mb-3">
            <input readonly type="text" [value]="parseInt(system.value.rfidTypeId) ? 'Mifare' : 'LEGIC'"
                   data-testid="overview-form-rfidType" class="form-control" id="rfidType">
            <label for="rfidType">{{ "SYSTEM.FIELD.RFID_TYPE.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="technical"></i>
            </div>
          </div>

          <div class="form-floating form-floating-custom mb-3">
            <table readonly id="informationTable" class="form-control h-auto">
              <tbody>
              <tr>
                <td>{{ "SYSTEM.FIELD.COUNT.LOCKING_DEVICES" | translate }}:</td>
                <td *ngIf="system.value.lockingDeviceCount">{{ parseInt(system.value.lockingDeviceCount) }}</td>
              </tr>
              <tr *ngIf="system.value.licenseOnlineCount && system.value.onlineCount && system.value.licenseOnlineCount > 0">
                <td>{{ "SYSTEM.FIELD.COUNT.ONLINE_DEVICES" | translate }}:</td>
                <td>{{ parseInt(system.value.onlineCount) }} / {{ parseInt(system.value.licenseOnlineCount) }}</td>
              </tr>
              <tr>
                <td>{{ "SYSTEM.FIELD.COUNT.LOCKING_MEDIA" | translate }}:</td>
                <td *ngIf="system.value.lockingMediaCount">{{ system.value.lockingMediaCount }}</td>
              </tr>
              <tr>
                <td>{{ "SYSTEM.FIELD.COUNT.USERS" | translate }}:</td>
                <td *ngIf="system.value.userCount">{{ system.value.userCount }}</td>
              </tr>
              <tr>
                <td>{{ "SYSTEM.FIELD.COUNT.ACCESS_PROFILES" | translate }}:</td>
                <td *ngIf="system.value.accessProfileCount">{{ system.value.accessProfileCount }}</td>
              </tr>
              </tbody>
            </table>
            <label for="informationTable">{{ "SYSTEM.FIELD.COUNT.TITLE" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="info"></i>
            </div>
          </div>

          <div class="form-floating form-floating-custom">
            <input readonly type="text" class="form-control border-bottom-0 rounded-0 rounded-top"
                   id="lastLoginTimestamp" data-testid="overview-form-lastLoginTimestamp"
                   [value]="lastLoginTimestamp" placeholder="-">
            <label for="lastLoginTimestamp">{{ "SYSTEM.FIELD.LAST_LOGIN_TIMESTAMP.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="date"></i>
            </div>
          </div>
          <div class="form-floating form-floating-custom mb-3">
            <input readonly type="text" class="form-control border-top-0 rounded-0 rounded-bottom" id="lastLoginUsername"
                   [value]="system.value.lastLoginUsername || ('SYSTEM.LAST_LOGIN.NO_USER' | translate)"
                   placeholder="-" data-testid="overview-form-lastLoginUsername">
            <label for="lastLoginUsername">{{ "SYSTEM.FIELD.LAST_LOGIN_USER.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="user"></i>
            </div>
          </div>

          <div class="form-floating form-floating-custom">
            <input readonly type="text" class="form-control border-bottom-0 rounded-0 rounded-top"
                   id="lastChangeTimestamp" data-testid="overview-form-lastChangeTimestamp"
                   [value]="lastChangeTimestamp" placeholder="-">
            <label for="lastChangeTimestamp">{{ "SYSTEM.FIELD.LAST_CHANGE_TIMESTAMP.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="date"></i>
            </div>
          </div>
          <div class="form-floating form-floating-custom">
            <input readonly type="text" class="form-control border-top-0 rounded-0 rounded-bottom" id="lastChangeUsername"
                   [value]="system.value.lastChangeUsername || ('SYSTEM.LAST_LOGIN.NO_USER' | translate)"
                   placeholder="-" data-testid="overview-form-lastChangeUsername">
            <label for="lastChangeUsername">{{ "SYSTEM.FIELD.LAST_CHANGE_USER.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="user"></i>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</form><!-- end form -->

