// this file is automatically generated by git.version.ts script
export const versions = {
      beta: undefined,
      branch: 'feature/fremdtransponder',
      buildTime: '2025-01-23T21:17:33.458Z',
      package: '@ces/cesentry-web-client',
      revision: '08e7efa5f',
      tag: 'v1.0.3',
      version: '1.3.0',
     };
