import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommissionManager } from 'src/app/core/services/auth-service/support-services/CommissionManager';
import { IoBridgeService } from 'src/app/core/services/io-bridge-service/io-bridge.service';
import { debounceTime } from 'rxjs';
import { BindingProcessError } from 'src/app/shared/entities/IOB/BindingProcessError';
import { CommissioningComponent } from 'src/app/shared/commissioning/commissioning.component';
import { ItemAction } from 'src/app/shared/models/Actions';
import { IoBridgeFlow } from 'src/app/core/enums/ioBridgeFlow';

@Component({
  selector: 'app-locking-device-rebinding',
  templateUrl: './locking-device-rebinding.component.html',
  styleUrl: './locking-device-rebinding.component.scss'
})
export class LockingDeviceRebindingComponent implements OnInit, OnDestroy {
  @ViewChild(CommissioningComponent) Commissioning!: CommissioningComponent;

  private _commissionManager: CommissionManager = CommissionManager.getInstance(IoBridgeFlow.DEVICE_REBIND);

  isLoading: boolean = false;
  globalBindingErrorProcess: BindingProcessError;

  providePin: string = '';
  provideSN: string = '';
  processAndPollingRunning: boolean = false;

  headerText: string = 'PROGRAMMING.MODAL.LOCKING_DEVICE.REPAIR';
  buttonText: string = 'PROGRAMMING.MODAL.BUTTON.CLOSE_LIST';

  addRemoveButton: ItemAction = new ItemAction (
    'PROGRAMMING.MODAL.LOCKING_DEVICE.SEND_AND_REPAIR',
    (): void => {this.startProcessIOBridge()},
    false,
    'btn btn-outline-primary',
    ''
  )

  constructor(
    private ioBridgeService: IoBridgeService,
  ){
    this.globalBindingErrorProcess = this.commissionManager.bindingProcessError;
    this.commissionManager.bindingProcessErrorObservable.pipe(debounceTime(500)).subscribe({
      next: value => this.globalBindingErrorProcess = value
    });
  }

  ngOnInit(): void {
    if (!this.globalBindingErrorProcess.blockTillReset) {
      this.isLoading = true;
      this.ioBridgeService.iobConnectionUpdater().then(() => {
        this.isLoading = false;
      });
    }
  }

  ngOnDestroy() {
    if (this.commissionManager.bindingProcessState.order == 0 && !this.globalBindingErrorProcess.blockTillReset) {
      this.commissionManager.reset();
    }
    this.ioBridgeService.iobConnectionUpdater();
  }

  async startProcessIOBridge() {
    this.Commissioning.startButtonClicked = true;
    this.rebind()
    this.Commissioning.startButtonClicked = false;
  }

  private async rebind() {
    await this.Commissioning.prepare();
    this.Commissioning.pollFetch().catch(() => this.processAndPollingRunning = false);
    const rebind: Response = await this.ioBridgeService.fetchRebindingDevice(this.commissionManager.bindingProcess.pin, this.provideSN, this.commissionManager.bindingProcess.objectUuid);
    if (rebind.ok) {
      await this.Commissioning.finishSuccessAndClose(this.provideSN);
    } else {
      await this.Commissioning.finishError(rebind);
    }
    this.providePin = this.commissionManager.bindingProcess.pin
    this.provideSN = this.commissionManager.bindingProcess.serialNumber
  }


  //

  get commissionManager(): CommissionManager {
    return this._commissionManager;
  }

  get pinInputEnabled(): boolean {
    return !((this.providePin.length != 6) || (this.provideSN.length < 5) || this.commissionManager.bindingProcessState.order! != 0) && !this.commissionManager.errorIsPresent;
  }

  get serialNumberInputEnabled(): boolean {
    return (this.Commissioning ? (!this.Commissioning.startButtonClicked && !this.isLoading) : false)
  }

}
