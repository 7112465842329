import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ChangePinComponent} from "./modals/change-pin/change-pin.component";
import {ChangePasswordComponent} from "./modals/change-password/change-password.component";
import {CoreModule} from "../../core/core.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {IoBridgeComponent} from "./context-menues/io-bridge/io-bridge.component";
import {DownloadComponent} from "./context-menues/io-bridge/download/download.component";
import {InfoComponent} from "./context-menues/io-bridge/download/info/info.component";
import {AngularDeviceInformationService} from "angular-device-information";
import {SharedModule} from "../../shared/shared.module";
import {IoBridgeIndicatorPipe} from "../../core/pipes/io-bridge/io-bridge-indicator.pipe";


@NgModule({
  declarations: [
    IoBridgeIndicatorPipe,
    ChangePinComponent,
    ChangePasswordComponent,
    IoBridgeComponent,
    DownloadComponent,
    InfoComponent
  ],
  providers: [
    IoBridgeIndicatorPipe,
    AngularDeviceInformationService
  ],
  exports: [
    IoBridgeIndicatorPipe,
    IoBridgeComponent
  ],
    imports: [
        CommonModule,
        CoreModule,
        ReactiveFormsModule,
        TranslateModule,
        FormsModule,
        SharedModule
    ]
})
export class TopbarModule { }
