<app-commissioning
    [commissionManager]="commissionManager"
    [globalBindingErrorProcess]="globalBindingErrorProcess"
    [isLoading]="isLoading"
    [providePin]="providePicc"
    [provideSN]="provideSN"
    [deviceName]="objectName"
    [processAndPollingRunning]="processAndPollingRunning"
    [headerText]="headerText"
    [buttonText]="buttonText"
    [addRemoveButton]="addRemoveButton"
    [pinInputEnabled]="pinInputEnabled">


    <!-- MEDIA NUMBER INPUT -->
    <div class="dtw-input">
        <div class="form-floating form-floating-custom flex-grow-1">
            <input type="text" class="form-control" id="sn-id"
                    [(ngModel)]="provideSN"
                    [disabled]="true">
            <label for="sn-id" class="text-truncate">{{ "PROGRAMMING.MODAL.INPUT_FIELDS.MEDIA_NUMBER" | translate }}</label>
            <div class="form-floating-icon">
                <i icon="id"></i>
            </div>
        </div>
    </div>
    <!-- NAME OUTPUT -->
    <!-- <div class="dtw-input">
        <div class="form-floating form-floating-custom flex-grow-1">
            <input type="text" class="form-control" id="name-id"
                [(ngModel)]="objectName"
                [disabled]="true">
            <label for="name-id" class="text-truncate">{{ "TIME_MODELS.FIELD.SHORT_NAME.TEXT" | translate }}</label>
            <div class="form-floating-icon">
                <i icon="name"></i>
            </div>
        </div>
    </div> -->
</app-commissioning>
