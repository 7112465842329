import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCloseCause } from 'src/app/core/enums/modalCloseCause';
import { CommissionManager } from 'src/app/core/services/auth-service/support-services/CommissionManager';
import { SessionManager } from 'src/app/core/services/auth-service/support-services/SessionManager';
import { IoBridgeService } from 'src/app/core/services/io-bridge-service/io-bridge.service';
import { BindingProcessError } from 'src/app/shared/entities/IOB/BindingProcessError';
import { ToastService } from 'src/app/shared/notification/toast/toast.service';
import { IoBridgeProcess } from 'src/app/shared/util/bindingStates';
import { ItemAction } from '../models/Actions';
import { IoBridgeFlow } from 'src/app/core/enums/ioBridgeFlow';

@Component({
  selector: 'app-commissioning',
  templateUrl: './commissioning.component.html',
  styleUrl: './commissioning.component.scss'
})
export class CommissioningComponent {
  @Input() commissionManager!: CommissionManager;

  @Input() isLoading!: boolean;
  @Input() globalBindingErrorProcess!: BindingProcessError;

  @Input() providePin!: string;
  @Input() provideSN!: string;
  @Input() deviceName!: string;
  @Input() processAndPollingRunning!: boolean;
  startButtonClicked!: boolean;

  @Input() headerText!: string;
  @Input() buttonText!: string;

  @Input() addRemoveButton!: ItemAction;
  @Input() pinInputEnabled!: boolean;

  constructor(private ngbModal: NgbActiveModal,
              private notification: ToastService,
              private ioBridgeService: IoBridgeService,
              protected ioBridgeProcess: IoBridgeProcess,
              private httpClient: HttpClient) {}

  get currentOrderNumber(): number {
    return this.ioBridgeProcess.getStateByName(this.commissionManager.bindingProcessState.name).order;
  }

  public async prepare() {
    this.commissionManager.bindingProcess.serialNumber = this.provideSN;
    this.commissionManager.bindingProcess.pin = this.providePin;

    await SessionManager.getInstance().forceNewToken(this.httpClient);
    await this.ioBridgeService.iobConnectionUpdater();

    this.processAndPollingRunning = true;
  }

  public async pollFetch() {
    for (; this.processAndPollingRunning && !this.commissionManager.bindingProcessError.blockTillReset;) {
      this.ioBridgeService.iobConnectionUpdater().catch(() => this.processAndPollingRunning = false);
      await new Promise(f => setTimeout(f, 500));
    }
  }

  public async finishError(error: Response) {
    this.processAndPollingRunning = false;

    let errorString: string = 'ErrGeneral';
    let msg: string = 'ErrGeneral';
    let errorCode: number | string = 0;

    if (error.body != null) {
      try {
        const body = await error.json();
        switch (typeof body.error) {
          case "string":
            errorString = body.error;
            msg = body.statusText;
            break;
          default:
            errorString = 'ErrGeneral';
            msg = body.statusText;
            break;
        }
        switch (typeof body.code) {
          case "string":
            errorCode = (body.code as string).replaceAll('\"', '');
            break
          case "number":
            errorCode = body.code as number;
            break;
        }
      } catch (ex) {
      }
    }
    this.commissionManager.bindingProcessError = new BindingProcessError(true, errorString, msg, errorCode);
    this.notification.showError('NOTIFICATION.TOAST.CES_ERROR.cesErrExecution');
  }

  public async finishSuccessAndClose(returnVal: any) {
    this.processAndPollingRunning = false;
    this.notification.showSuccess(`PROGRAMMING.STATES.SUCCESS`);
    if(this.commissionManager.bindingProcess.flow != IoBridgeFlow.MEDIA_BIND){
        this.onClose(returnVal);
        return new Promise(() => setTimeout(() => this.commissionManager.reset(), 300));
    }else{
        return this.onReset();
    }
  }

  onClose(cause: ModalCloseCause) {
    this.ngbModal.close(cause);
  }
  onReset() {
    this.commissionManager.reset();
    this.commissionManager = CommissionManager.getInstance(IoBridgeFlow.DEVICE_BIND).init()
  }

  onAbort(): void {
    this.isLoading =true;
    this.ioBridgeService.fetchResetState()
      .then(() => {
        this.ioBridgeService.iobConnectionUpdater();
        this.commissionManager.reset();
        this.notification.showSuccess('NOTIFICATION.TOAST.SUCCESS.DEFAULT');
      })
      .catch(() => this.notification.showError('NOTIFICATION.TOAST.ERROR.DEFAULT'))
      .finally(() => this.isLoading = false);
  }

  protected readonly ModalCloseCause = ModalCloseCause;

  
  get startButtonEnabled(): boolean {
    return this.commissionManager.bindingProcessState.order! == 0 &&
      !this.commissionManager.errorIsPresent && !this.startButtonClicked;
  }
}
