<!-- start page title -->
<div id="page-title-div" class="page-title-box d-flex gap-1">

  <div class="d-flex align-items-center justify-content-start p-2">
    <h3 class="mb-0">{{title}}</h3>
  </div>

  <div id="fadeout-container" class="d-flex flex-grow-1 gap-1 fadable" [ngClass]="_shiftRight ? 'buttons-right-side' : {'fadeOut': itemSelected}">

    <!-- SEARCH -->
    <div *ngIf="!disableSearch" id="search-bar" class="d-flex justify-content-between flex-grow-1">
      <form class="search-box w-100 h-100 position-relative" [formGroup]="searchForm" (change)="updateSearch($event)">
        <i class="mdi mdi-magnify search-icon d-flex align-items-center h-100"></i>
        <input [readonly]="itemSelected" id="search-bar-input" data-testid="search-bar-input" type="text" class="form-control rounded h-100" formControlName="searchText"
               placeholder="{{ 'PAGETITLE.SEARCH' | translate }}" [ngbTypeahead]="typeahead"
               (selectItem)="updateSearch($event)" (change)="updateSearch($event)" (input)="updateSearch($event)">
      </form>
    </div>

    <!-- ACTIONS -->
    <div *ngIf="_pagetitleActions.length > 0 && !disableButtons" id="pagetitle-button" data-testid="pagetitle-button" class="d-flex justify-content-end gap-1">
      <button *ngFor="let pageAction of _pagetitleActions" type="button" class="btn"
              (click)="onClickAction(pageAction)" [attr.disabled]="pageAction.disabled ? true : null"
              [ngClass]="pageAction.disabled ? 'btn-outline-light text-muted' : pageAction.styleClasses? pageAction.styleClasses : 'btn-outline-secondary'">
        <i *ngIf="pageAction.icon != null" [ngClass]="pageAction.icon"></i>
        {{ pageAction.key | translate }}
      </button>
    </div>
    <ng-content></ng-content>
  </div>
</div>
<!-- end page title -->
