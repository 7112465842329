import { NgModule } from '@angular/core';
import {LockingDevicesComponent} from "./locking-devices.component";
import {CommonModule} from "@angular/common";
import {CoreModule} from "../../core/core.module";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {
  NgbDropdown, NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle,
  NgbNav,
  NgbNavModule,
  NgbNavOutlet, NgbProgressbar
} from "@ng-bootstrap/ng-bootstrap";
import {LockingDeviceDevicesModule} from "./locking-device-devices/locking-device-devices.module";
import {LockingDeviceGroupsModule} from "./locking-device-groups/locking-device-groups.module";
import {SharedModule} from "../../shared/shared.module";
import {RouterLink} from "@angular/router";
import { LockingDeviceBindingComponent } from './modals/locking-device-binding/locking-device-binding.component';
import { LockingDeviceRebindingComponent } from './modals/locking-device-rebinding/locking-device-rebinding.component';
import { LockingDeviceUnbindingComponent } from './modals/locking-device-unbinding/locking-device-unbinding.component';

@NgModule({
  declarations: [
    LockingDevicesComponent,
    LockingDeviceBindingComponent,
    LockingDeviceRebindingComponent,
    LockingDeviceUnbindingComponent
  ],
  exports: [
    LockingDevicesComponent
  ],
  providers: [
  ],
  imports: [
    CommonModule,
    CoreModule,
    TranslateModule,
    FormsModule,
    NgbNav,
    LockingDeviceDevicesModule,
    LockingDeviceGroupsModule,
    NgbNavOutlet,
    SharedModule,
    ReactiveFormsModule,
    NgbNavModule,
    RouterLink,
    NgbDropdownMenu,
    NgbDropdownToggle,
    NgbDropdown,
    NgbDropdownItem,
    NgbProgressbar
  ]
})
export class LockingDevicesModule { }
