import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'collapse-container',
  templateUrl: './collapse-container.component.html',
  styleUrls: ['./collapse-container.component.scss'],
  exportAs: 'collapseContainer'
})
export class CollapseContainerComponent implements OnInit {

  @Input() set collapse(collapse: boolean) {

    if(!collapse && !this.enableContent) {
      this.enableContent = true
    }

    setTimeout(() => { // defer to enable smooth animation
      this.collapseState = collapse
    }, 1)
  }

  @Input() fading = false
  @Input() initialCollapse = false

  collapseState = false
  enableContent = true

  constructor() { }

  ngOnInit(): void {
    if(this.initialCollapse) {
      this.collapseState = true
      this.enableContent = false
    }
  }

  onHidden() {
    this.enableContent = false
  }
}
