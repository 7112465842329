import {APP_INITIALIZER, Injector, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NgbDatepickerI18n, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpHandler,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import {PagesModule} from './pages/pages.module';
import {LayoutsModule} from './layouts/layouts.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NavigationService} from './core/services/navigation-service/navigation.service';
import {environment} from '../environments/environment';
import {AddHeaderInterceptor} from "./core/Interceptors/AddHeaderInterceptor";
import {NodeManager} from "./core/services/auth-service/support-services/NodeManager";
import {NodeConfiguration, NodeConfigurationOld, ToNodeConfiguration} from "./core/interfaces/nodeConfiguration";
import {ErrorInterceptor} from "./core/Interceptors/error.interceptor";
import {LocalStorageSchemaService} from "./core/services/json-schema-service/local-storage-schema.service";
import {take} from "rxjs";
import {CoreModule} from "./core/core.module";
import {SharedModule} from "./shared/shared.module";
import {TopbarModule} from "./layouts/topbar/topbar.module";

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

const availableLanguages = ['en', 'de', 'fr', 'nl'];

function getDefaultLanguage(): string {
  let browserLanguage = window?.navigator?.language;
  if (!browserLanguage || !availableLanguages.includes(browserLanguage)) {
    browserLanguage = 'en';
  }

  return browserLanguage;
}

export function initializeRepository(httpClient: HttpClient) {
  return async () => {
    console.log('Initializing');

    // Initialize language if not set
    if (!(new LocalStorageSchemaService().localStorage('lang').isValid)) {
      window.localStorage.setItem('lang', getDefaultLanguage());
    }

    await new Promise<void>((resolve) => {
      // Initialize language if not set
      if (window.localStorage.getItem('lang') == undefined) {
        window.localStorage.setItem('lang', getDefaultLanguage());
      }

      console.info('Receiving default configuration');

      httpClient.get<NodeConfiguration | NodeConfigurationOld>('settings/config.json', {observe: 'response'}).pipe(take(1)).subscribe({
        next: (next) => {
          const body: NodeConfiguration | NodeConfigurationOld | null = next.body;
          if (next.ok && body != null) {
            NodeManager.getInstance().overrideMasterNodeConfiguration(ToNodeConfiguration(body));
          }
        },
        error: () => console.error("Error"),
        complete: () => console.info('Receiving default done')
      });

      resolve(); // Resolve the promise once initialization is done
    });

    console.info('Initializing done.');
  };
}

export function initializeNavigation(navigation: NavigationService) {
  return async () => {
    navigation.setRoot('/');
  };
}

export function initializeLogging() {
  return async () => {
    const logByQuery = location?.search.indexOf('logger=true') > 0;
    if (!logByQuery && environment.production) {
      (window as any).console = {
        ...(window as any).console,
        log: (text: string) => {
          return;
        },
        info: (text: string) => {
          return;
        },
        warn: (text: string) => {
          return;
        },
      };
    }
  };
}

@NgModule({
  declarations: [
    AppComponent
  ],
  bootstrap: [AppComponent],
  imports: [TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: createTranslateLoader,
      deps: [HttpClient],
    },
  }),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PagesModule,
    LayoutsModule,
    NgbModule,
    FormsModule,
    CoreModule,
    ReactiveFormsModule,
    SharedModule,
    TopbarModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeLogging,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeRepository,
      deps: [HttpClient],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeNavigation,
      deps: [NavigationService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
export class AppModule {
  constructor() {
  }
}
