import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from "../../../core/services/api-service/api.service";
import {PasswordComplexity, passwordComplexity} from "../../../shared/util/password-complexity";
import {ClientDto} from "../../../shared/entities/client/ClientDto";
import {firstValueFrom} from "rxjs";
import {FirmwareGetStatusDto} from "../../../shared/entities/firmware/FirmwareGetStatusDto";
import {ToastService} from "../../../shared/notification/toast/toast.service";
import {ChangeDetectorValue} from "../../../shared/util/change-detector/ChangeDetectorValue";
import {parseInt} from "lodash";

@Component({
  selector: 'app-system-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SystemSettingsComponent implements OnInit {
  @Input() system: ChangeDetectorValue = new ChangeDetectorValue(ClientDto.emptyClientDto());

  firmwareStatus: FirmwareGetStatusDto[] = [];
  firmwareStatusCylinder?: FirmwareGetStatusDto;
  firmwareStatusMount?: FirmwareGetStatusDto;
  firmwareStatusWallTerminal?: FirmwareGetStatusDto;

  passwordComplexityData: PasswordComplexity[] = passwordComplexity;

  validatorMaxLength64: number = 64;

  firmwareUpdateRunning: boolean = false;
  paramGroupsExist: boolean = false;

  constructor(private notification: ToastService,
              private apiService: ApiService) {
  }

  ngOnInit(): void {
    this.loadFirmwareData();
  }

  loadFirmwareData() {
    this.apiService.firmware.getStatus().then(async observe => {
      const firmwareStatus = await firstValueFrom(observe);
      if (firmwareStatus != null) {
        this.firmwareStatus = firmwareStatus;

        this.firmwareStatus?.forEach((value) => {
          switch (value.partTypeId) {
            case 1:
              this.firmwareStatusCylinder = value;
              break;
            case 2:
              this.firmwareStatusMount = value;
              break;
            case 3:
              this.firmwareStatusWallTerminal = value;
              break;
          }
        });
      }
    })
  }

  async triggerFirmwareSync(e: any) {
    e.preventDefault();

    if (this.firmwareUpdateRunning) {
      return;
    }

    this.firmwareUpdateRunning = true;
    return this.runFirmwareUpdate();
  }

  async runFirmwareUpdate() {
    if ((await firstValueFrom(await this.apiService.firmware.triggerSync())).ok) {
      this.notification.showSuccess('NOTIFICATION.TOAST.SUCCESS.DEFAULT');
      this.loadFirmwareData();
    }
    this.firmwareUpdateRunning = false;
  }

  newFirmwareAvailable() {
    return !!this.firmwareStatus.find((f) => f.updateRequired)
  }

  protected readonly parseInt = parseInt;
}
