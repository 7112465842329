<div [loadingCes]="loading" id="content">
  <div class="modal-header border-bottom-0">
    <h4 class="modal-title" id="modal-basic-title">{{ "USERMENU.CHANGE_PASSWORD.TITLE" | translate }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="onClose()"></button>
  </div>
  <div class="modal-body">
    <form>
      <p class="text-muted">{{ "AUTH.CHANGE_PASSWORD.INFO" | translate }}</p>

      <div class="form-floating form-floating-custom mb-4">
        <input type="password" class="form-control" id="oldPassword"
               data-testid="change-password-form-oldPassword"
               name="oldPassword" placeholder="-" viewablePassword
               [(ngModel)]="changePasswordValue.value.oldPassword"
               [ngClass]="{ 'is-invalid': changePasswordValue | hasChangeErrors: 'oldPassword': 'Required' }">
        <label for="oldPassword">{{ "AUTH.FIELD.CURRENT_PASSWORD.TITLE" | translate }}</label>
        <div class="form-floating-icon">
          <i icon="password-old"></i>
        </div>
        <div *ngIf="changePasswordValue | hasChangeErrors: 'oldPassword': 'required'" class="invalid-feedback">
          <div >{{ "AUTH.FIELD.CURRENT_PASSWORD.FORM.REQUIRED" | translate }}</div>
        </div>
      </div>

      <p class="text-muted">{{ "AUTH.CHANGE_PASSWORD.INFO_SECTION" | translate }}</p>

      <div class="form-floating form-floating-custom mb-3">
        <input type="password" class="form-control" id="new-password"
               name="newPassword" placeholder="-" viewablePassword
               data-testid="change-password-form-newPassword"
               [(ngModel)]="changePasswordValue.value.newPassword"
               [ngClass]="{ 'is-invalid': changePasswordValue | hasChangeErrors: 'newPassword' }">
        <label for="new-password">{{ "AUTH.FIELD.NEW_PASSWORD.TITLE" | translate }}</label>
        <div class="form-floating-icon">
          <i icon="password"></i>
        </div>
        <div *ngIf="changePasswordValue | hasChangeErrors: 'newPassword'" class="invalid-feedback">
          <div *ngIf="changePasswordValue | hasChangeErrors: 'newPassword': 'Required'">{{ "AUTH.FIELD.NEW_PASSWORD.FORM.REQUIRED" | translate }}</div>
          <div *ngIf="changePasswordValue | hasChangeErrors: 'newPassword':'MustNotMatch'">{{ "AUTH.FIELD.NEW_PASSWORD.FORM.INVALID" | translate }}</div>
        </div>
      </div>


      <div class="form-floating form-floating-custom mb-3">
        <input type="password" class="form-control" id="confirm-password"
               data-testid="change-password-form-confirmPassword"
               name="confirmPassword" placeholder="-" viewablePassword
               [(ngModel)]="changePasswordValue.value.confirmPassword"
               [ngClass]="{ 'is-invalid': changePasswordValue | hasChangeErrors: 'confirmPassword' }">
        <label for="confirm-password">{{ "AUTH.FIELD.CONFIRM_PASSWORD.TITLE" | translate }}</label>
        <div class="form-floating-icon">
          <i icon="password-confirm"></i>
        </div>
        <div *ngIf="changePasswordValue | hasChangeErrors: 'confirmPassword'" class="invalid-feedback">
          <span *ngIf="changePasswordValue | hasChangeErrors: 'confirmPassword':'Required'">{{ "AUTH.FIELD.CONFIRM_PASSWORD.FORM.REQUIRED" | translate }}</span>
          <span *ngIf="changePasswordValue | hasChangeErrors: 'confirmPassword':'MustMatch'">{{ "AUTH.FIELD.CONFIRM_PASSWORD.FORM.INVALID" | translate }}</span>
        </div>
      </div>

      <div *ngIf="!changePasswordValue.isSpecificValid('pattern')" class="mt-3">
        <app-password-complexity
          [showHeadline]="false"
          [value]="changePasswordValue.value.newPassword">
        </app-password-complexity>
      </div>
    </form>

  </div>
  <div class="modal-footer border-top-0">
    <button type="button" class="btn text-primary btn-outline-light" (click)="onSubmit()">{{ "USERMENU.CHANGE_PASSWORD.TITLE" | translate }}</button>
    <button type="button" class="btn text-secondary btn-outline-light" (click)="onClose()">{{ "NOTIFICATION.ALERT.BUTTON.CANCEL" | translate }}</button>
  </div>
</div>
