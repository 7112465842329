import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IoBridgeService } from 'src/app/core/services/io-bridge-service/io-bridge.service';
import { CommissionManager } from 'src/app/core/services/auth-service/support-services/CommissionManager';
import { TranslateService } from '@ngx-translate/core';
import { ModalCloseCause } from 'src/app/core/enums/modalCloseCause';
import { ModalContainerContent } from 'src/app/shared/notification/modal/modal-container.class';
import { ModalService } from 'src/app/shared/notification/modal/modal.service';
import { debounceTime } from 'rxjs';
import { BindingProcessError } from 'src/app/shared/entities/IOB/BindingProcessError';
import { CommissioningComponent } from 'src/app/shared/commissioning/commissioning.component';
import { ItemAction } from 'src/app/shared/models/Actions';
import { IoBridgeFlow } from 'src/app/core/enums/ioBridgeFlow';

@Component({
  selector: 'app-locking-device-unbinding',
  templateUrl: './locking-device-unbinding.component.html',
  styleUrl: './locking-device-unbinding.component.scss'
})
export class LockingDeviceUnbindingComponent implements OnInit, OnDestroy {
  @ViewChild(CommissioningComponent) Commissioning!: CommissioningComponent;

  private _commissionManager: CommissionManager = CommissionManager.getInstance(IoBridgeFlow.DEVICE_UNBIND);

  isLoading: boolean = false;
  globalBindingErrorProcess: BindingProcessError;

  provideSN: string = '';
  deviceName: string = '';
  processAndPollingRunning: boolean = false;

  headerText: string = 'PROGRAMMING.MODAL.LOCKING_DEVICE.REMOVE';
  buttonText: string = 'PROGRAMMING.MODAL.BUTTON.CLOSE_DETAIL';

  addRemoveButton: ItemAction = new ItemAction (
    'PROGRAMMING.MODAL.LOCKING_DEVICE.SEND_AND_UNBIND',
    (): void => {this.startProcessIOBridge()},
    false,
    'btn btn-outline-danger',
    ''
  )

  constructor(
    private ioBridgeService: IoBridgeService,
    private translate: TranslateService,
    private alertService: ModalService,
  ){
    this.globalBindingErrorProcess = this.commissionManager.bindingProcessError;
    this.commissionManager.bindingProcessErrorObservable.pipe(debounceTime(500)).subscribe({
      next: value => this.globalBindingErrorProcess = value
    });
  }

  ngOnInit(): void {
    this.provideSN = this.commissionManager.bindingProcess.serialNumber;
    this.deviceName = this.commissionManager.bindingProcess.objectName;

    if (!this.globalBindingErrorProcess.blockTillReset) {
      this.isLoading = true;
      this.ioBridgeService.iobConnectionUpdater().then(() => {
        this.isLoading = false;
      });
    }
  }

  ngOnDestroy() {
    if (this.commissionManager.bindingProcessState.order == 0 && !this.globalBindingErrorProcess.blockTillReset) {
      this.commissionManager.reset();
    }
    this.ioBridgeService.iobConnectionUpdater();
  }

  async startProcessIOBridge() {
    this.Commissioning.startButtonClicked = true;
    this.unbind()
    this.Commissioning.startButtonClicked = false;
  }

  private async unbind() {
    const modalContent: ModalContainerContent = new ModalContainerContent(
      this.translate.instant("LOCKING_DEVICES.DEVICES.ITEM_LIST.UNBIND.TITLE"),
      this.translate.instant('LOCKING_DEVICES.DEVICES.ITEM_LIST.UNBIND.MESSAGE', {name: this.commissionManager.bindingProcess.objectName})
    );
    if (await this.alertService.openModal(modalContent)) {
      await this.Commissioning.prepare();
      this.Commissioning.pollFetch().catch(() => this.processAndPollingRunning = false);
      const unbind: Response = await this.ioBridgeService.fetchUnbindingDevice(this.commissionManager.bindingProcess.objectUuid!);
      if (unbind.ok) {
        await this.Commissioning.finishSuccessAndClose(ModalCloseCause.SUCCESS || 3);
      } else {
        await this.Commissioning.finishError(unbind);
      }
      this.provideSN = this.commissionManager.bindingProcess.serialNumber
      this.deviceName = this.commissionManager.bindingProcess.objectName
    }
  }


  //

  get commissionManager(): CommissionManager {
    return this._commissionManager;
  }

  get pinInputEnabled(): boolean {
    return !(this.commissionManager.bindingProcessState.order! != 0) && !this.commissionManager.errorIsPresent;
  }

}
