import {Component, EventEmitter, OnInit, Output, Type, ViewChild} from '@angular/core';
import {Router} from '@angular/router';

import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../../core/services/language-service/language.service';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ApiService} from "../../core/services/api-service/api.service";
import {ChangePinComponent} from "./modals/change-pin/change-pin.component";
import {ChangePasswordComponent} from "./modals/change-password/change-password.component";
import {IModalContent} from "./modals/IModalContent";
import {SessionManager} from "../../core/services/auth-service/support-services/SessionManager";
import {IoBridgeComponent} from "./context-menues/io-bridge/io-bridge.component";
import {debounceTime} from "rxjs";
import {environment} from "../../../environments/environment";
import {IoBridgeStatus} from "../../core/enums/ioBridgeStatus";
import {CommissionManager} from "../../core/services/auth-service/support-services/CommissionManager";
import {BindingProcess} from "../../shared/entities/IOB/BindingProcess";
import {ROLE} from "../../shared/lookup/role.lookup";
import {LookupMapping} from "../../shared/lookup/lookup";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar Component
 */
export class TopbarComponent implements OnInit {

  @ViewChild(IoBridgeComponent) ioBridge?: IoBridgeComponent;
  isLoading: boolean = false;
  private modalOpened: boolean = false;

  modalContent: IModalContentEntry[] = [
    {icon: 'mdi mdi-asterisk ', title: 'USERMENU.CHANGE_PIN.TITLE', content: ChangePinComponent},
    {icon: 'mdi mdi-lock-open-outline ', title: 'USERMENU.CHANGE_PASSWORD.TITLE', content: ChangePasswordComponent}
  ]

  element: any;
  flagvalue: any;
  currentLang: any;
  countryName: any;
  valueset: any;

  constructor(
    private router: Router,
    public languageService: LanguageService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private apiService: ApiService) {
  }

  getCurrentUserRole(): string {
    const role: LookupMapping[] = ROLE.filter(value => SessionManager.getInstance().sessionCredentials?.roleId! == value.id);
    if (role.length > 0) {
      return this.translate.instant(`USERS.USERS.ROLE.${role[0].value.toUpperCase() || 'UNKNOWN'}`);
    }
    return this.translate.instant("AUTH.NO_ROLE");
  }

  get currentUser(): string {
    return SessionManager.getInstance().sessionCredentials?.username || this.translate.instant("AUTH.NO_USER");
  }

  get currentUserImage(): string {
    return this.apiService.user.currentUserImage;
  }

  @Output() mobileMenuButtonClicked = new EventEmitter();


  /***
   * Language Listing
   */
  listLang = [
    {text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en'},
    {text: 'French', flag: 'assets/images/flags/french.jpg', lang: 'fr'},
    {text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de'},
    {text: 'Netherland', flag: 'assets/images/flags/netherland.jpg', lang: 'nl'},
  ];

  @Output() settingsButtonClicked = new EventEmitter();

  async ngOnInit() {
    // Cookies wise Language set
    this.currentLang = this.languageService.getCurrentLanguage();
    const val = this.listLang.filter(x => x.lang === this.currentLang);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = 'assets/images/flags/us.jpg';
      }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }

    this.languageService.languageChanged$.subscribe(language => {
      SessionManager.getInstance().setNewLanguage(SessionManager.getInstance().sessionCredentials, language);
    });

    if (this.isIOBridgeEnabled) {
      CommissionManager.getInstance().bindingProcessObservable.pipe(debounceTime(50)).subscribe((bindingProcess: BindingProcess) => {
        this.isLoading = bindingProcess.isLoading;
      });
    }
  }

  /***
   * Language Value Set
   */
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.currentLang = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  async logout() {
    await this.router.navigate(['/user/logout'])
  }

  async openModal(modalContent: IModalContentEntry) {
    this.modalService.open(modalContent.content, {ariaLabelledBy: 'modal-basic-title'});
  }

  private async updateUserImage() {

  }

  get isIOBridgeEnabled(): boolean {
    return SessionManager.getInstance().isAdminOrHigher;
  }

  get dtwStatus(): IoBridgeStatus {
    return CommissionManager.getInstance().bindingProcess.dtwStatus;
  }

  get iobStatus(): IoBridgeStatus {
    return CommissionManager.getInstance().bindingProcess.ioBridgeStatus;
  }

  protected readonly environment = environment;

}

interface IModalContentEntry {
  icon: string
  title: string
  content: Type<IModalContent>
}
