import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dateTimeRecurring'
})
export class DateTimeRecurringPipe implements PipeTransform {

  constructor() {
  }

  transform(value: { year: number; month: number; day: number }, recurring: boolean): string {
    const day: string = `${value.day < 10 ? `0${value.day}` : value.day}`;
    const month: string = `${value.month < 10 ? `0${value.month}` : value.month}`;
    let year: string = `${recurring ? 'xxxx' : value.year}`;
    for (; year.length < 4; year = `0${year}`) {
    }
    return `${day}.${month}.${year}`;
  }

}
