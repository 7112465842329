import {ChangeDetectorModel, ChangeDetectorValue} from "../ChangeDetectorValue";
import {LockingMediaDto} from "../../../entities/LockingMediaDto";
import {EventEmitter, input} from "@angular/core";
import {FormatterService} from "../../../../core/services/formatter-service/formatter.service";
import {values} from "lodash";

export class LockingDeviceGroupFormCdm implements ChangeDetectorModel {
  valueChange: EventEmitter<any>;

  private _id: number = 0;
  private _uuid: string = '';
  private _name: string = '';
  private _description: string = '';
  private _groupTypeId: number = 0;

  public nameMaxLength: number = 32;
  public descriptionMaxLength: number = 64;

  constructor() {
    this.valueChange = new EventEmitter<any>();
  }

  validators(): Map<string, (input: any) => boolean> {
    return ChangeDetectorValue.mergeValidatorMaps(
      ChangeDetectorValue.createNumberValidatorMinMaxRequired('name', 1, this.nameMaxLength),
      new Map<string, (input: any) => boolean>()
        .set('description', (input: any) => {
          return (input._description || '').length <= this.descriptionMaxLength;
        })
    );
  }

  set values(values: any) {
    if (values && typeof values == 'object') {
      for (let key in values) {
        const newVal = this.hasProp(values, key) ? values[key] : this.hasProp(this, `_${key}`) ? values[`_${key}`] : undefined;
        //@ts-ignore
        this.hasProp(this, key) ? this[key] = newVal : this.hasProp(this, `_${key}`) ? this[`_${key}`] = newVal : () => {
        };
      }
    }
  }

  private hasProp(thisArg: any, args_0: string): boolean {
    return Object.prototype.hasOwnProperty.call(thisArg, args_0);
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
    this.valueChange.emit(true);
  }

  get uuid(): string {
    return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
    this.valueChange.emit(true);
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
    this.valueChange.emit(true);
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
    this.valueChange.emit(true);
  }

  get groupTypeId(): number {
    return this._groupTypeId;
  }

  set groupTypeId(value: number) {
    this._groupTypeId = value;
    this.valueChange.emit(true);
  }

  viewablePasswordFields: string[] = [];
}
