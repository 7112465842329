<div class="container">
  <form>
    <div class="row row-cols-lg-6 g-4">

      <div class="card card-body shadow-none d-flex">

        <div class="form-floating form-floating-custom mb-3">
          <input [readonly]="accessReadonlyGetter" type="text" class="form-control"
                 id="name" name="name"
                 [(ngModel)]="accessProfileItem?.value.name"
                 [ngClass]="{ 'pending-changes' : (accessProfileItem?.hasSpecificChanges('name') && accessProfileItem?.isSpecificValid('requiredName', 'maxLengthName')),
                 'invalid-feedback-ngd': !accessProfileItem?.isSpecificValid('requiredName', 'maxLengthName')}"
                 placeholder="-">
          <label for="name">{{ "ACCESS_PROFILES.FIELD.NAME.TEXT" | translate }}*</label>
          <div class="form-floating-icon">
            <i icon="name"></i>
          </div>
          @if (!accessProfileItem?.isSpecificValid('requiredName', 'maxLengthName')) {
            <div class="invalid-feedback">
              <div
                *ngIf="!accessProfileItem?.isSpecificValid('requiredName')">{{ "ACCESS_PROFILES.FIELD.NAME.REQUIRED" | translate }}
              </div>
              <div *ngIf="!accessProfileItem?.isSpecificValid('maxLengthName')">
                {{ "NOTIFICATION.TOAST.CES_ERROR.cesErrStructTagBadMaxLength" | translate | errorTranslate: ("ACCESS_PROFILES.FIELD.NAME.TEXT" | translate) : ('' + validatorMaxLength32) }}
              </div>
            </div>
          }

        </div>

        <div class="form-floating form-floating-custom mb-3">
          <input [readonly]="accessReadonlyGetter" type="text" class="form-control"
                 id="description" name="description"
                 [(ngModel)]="accessProfileItem?.value.description"
                 [ngClass]="{'pending-changes' : (accessProfileItem?.hasSpecificChanges('description') && accessProfileItem?.isSpecificValid('maxLengthDescription')),
                          'invalid-feedback-ngd': !accessProfileItem?.isSpecificValid('maxLengthDescription')}"
                 placeholder="-">
          <label for="description">{{ "ACCESS_PROFILES.FIELD.DESCRIPTION.TEXT" | translate }}</label>
          <div class="form-floating-icon">
            <i icon="description"></i>
          </div>
          <div *ngIf="!accessProfileItem?.isSpecificValid('maxLengthDescription')" class="invalid-feedback">
            <div>
              {{ "NOTIFICATION.TOAST.CES_ERROR.cesErrStructTagBadMaxLength" | translate | errorTranslate: ("ACCESS_PROFILES.FIELD.DESCRIPTION.TEXT" | translate) : ('' + validatorMaxLength64) }}
            </div>
          </div>
        </div>

        <div class="form-floating form-floating-custom mb-3"
             [ngClass]="{'pending-changes' : accessProfileItem?.hasSpecificChanges('accessTypeId')}">
          <select *ngIf="(!accessReadonlyGetter); else accessType" class="form-select"
                  id="accessType" name="accessTypeId" [(ngModel)]="accessProfileItem?.value.accessTypeId">
            <option *ngFor="let type of ACCESS_TYPE" [value]="type.id">
              {{ ('GENERAL.' + type.value.toUpperCase()) | translate }}
            </option>
          </select>
          <ng-template #accessType>
            <input readonly id="accessType" class="form-control"
                   [value]="(accessProfileItem?.value.accessTypeId == 2 ? 'GENERAL.ALLOWED' : 'GENERAL.DENIED') | translate">
          </ng-template>
          <label for="accessType">{{ "ACCESS_PROFILES.FIELD.ACCESS_TYPE.TEXT" | translate }}</label>
          <div class="form-floating-icon">
            <i icon="security"></i>
          </div>
        </div>

        <div *ngIf="licenseTypeId > 1" class="form-floating form-floating-custom mb-3 switch-new-design-black"
             [ngClass]="{'pending-changes' : accessProfileItem?.hasSpecificChanges('officeFunction')}">
          <div id="formOfficeFunction" class="form-control" [attr.readonly]="accessReadonlyGetter ? true : null">
            <div class="form-check form-switch form-switch-md"
                 [ngClass]="{'pointer-events-none': accessReadonlyGetter}">
              <input [switchDisabled]="accessReadonlyGetter" type="checkbox" class="form-check-input"
                     id="officeFunction" name="officeFunction"
                     [(ngModel)]="accessProfileItem?.value.officeFunction">
            </div>
          </div>
          <label for="officeFunction">{{ "ACCESS_PROFILES.FIELD.OFFICE_FUNCTION.TEXT" | translate }}</label>
          <div class="form-floating-icon">
            <i icon="security"></i>
          </div>
        </div>

        <div *ngIf="licenseTypeId > 1" class="form-floating form-floating-custom switch-new-design-black"
             [ngClass]="{'pending-changes' : accessProfileItem?.hasSpecificChanges('pin')}">
          <div id="formPin" class="form-control" [attr.readonly]="accessReadonlyGetter ? true : null">
            <div class="form-check form-switch form-switch-md"
                 [ngClass]="{'pointer-events-none' : accessReadonlyGetter}">
              <input [switchDisabled]="accessReadonlyGetter" type="checkbox" class="form-check-input" id="pin"
                     name="pin" [(ngModel)]="accessProfileItem?.value.pin">
            </div>
          </div>
          <label for="pin">{{ "ACCESS_PROFILES.FIELD.PIN.TEXT" | translate }}</label>
          <div class="form-floating-icon">
            <i icon="security"></i>
          </div>
        </div>

      </div>

    </div>
  </form><!-- end form -->
</div>
