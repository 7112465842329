import {Injectable} from "@angular/core";
import {UserWithImageCdm} from "./change-detector/models/user-with-image.cdm";
import {UserAccessDto} from "../entities/user/UserAccessDto";
import {UserMediaDto} from "../entities/user/UserMediaDto";
import {UserDto} from "../entities/user/UserDto";

@Injectable({
  providedIn: 'root'
})
export class Helper {

  constructor() {
  }

  // toUserWithImage creates a new UserWithImage object
  // Its needed so the accessProfiles and medias are assigned properly
  public static TO_USER_WITH_IMAGE(userDto: UserDto): UserWithImageCdm {
    let user = new UserWithImageCdm();
    const accessProfiles: UserAccessDto[] | undefined = userDto.accessProfiles;
    const media: UserMediaDto[] | undefined = userDto.media;
    userDto.accessProfiles = undefined;
    userDto.media = undefined;
    user.values = userDto;
    user.accessProfiles = accessProfiles;
    user.media = media;
    if (user.dateOfBirth == null) {
      user.dateOfBirth = undefined;
    }
    return user;
  }

}
