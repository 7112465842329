import {Inject, inject, Injectable, LOCALE_ID} from '@angular/core';
import {NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {TIME_MODEL_MONTH_TYPE, TIME_MODEL_WEEKDAY_TYPE} from "../lookup/timemodel.lookup";
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class DateTimeWeekdayConverter extends NgbDatepickerI18n {
  translate = inject(TranslateService);

  constructor(@Inject(LOCALE_ID) private _locale: string) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return this.translate.instant(`TIME_MODELS.WEEKDAY.${[...TIME_MODEL_WEEKDAY_TYPE.filter(value => value.id == weekday), TIME_MODEL_WEEKDAY_TYPE[0]][0].value.toUpperCase()}`);
  }

  getMonthShortName(month: number): string {
    return this.translate.instant(`TIME_MODELS.MONTH.${[...TIME_MODEL_MONTH_TYPE.filter(value => value.id == month), TIME_MODEL_MONTH_TYPE[0]][0].value.toUpperCase()}`)
      .substring(0, 3);
  }

  getMonthFullName(month: number): string {
    return this.translate.instant(`TIME_MODELS.MONTH.${[...TIME_MODEL_MONTH_TYPE.filter(value => value.id == month), TIME_MODEL_MONTH_TYPE[0]][0].value.toUpperCase()}`);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  getWeekdayLabel(weekday: number, width?: Exclude<Intl.DateTimeFormatOptions["weekday"], undefined>): string {
    return this.translate.instant(`TIME_MODELS.WEEKDAY.${[...TIME_MODEL_WEEKDAY_TYPE.filter(value => value.id == weekday), TIME_MODEL_WEEKDAY_TYPE[0]][0].value.toUpperCase()}`);
  }
}
