import {Component, EventEmitter, input, Input, Output} from '@angular/core';

import {ApiService} from "../../../../../core/services/api-service/api.service";
import {FormatterService} from "../../../../../core/services/formatter-service/formatter.service";
import {TranslateService} from "@ngx-translate/core";
import {TimeModelDto} from "../../../../../shared/entities/TimeModel/TimeModelDto";
import {SessionManager} from "../../../../../core/services/auth-service/support-services/SessionManager";
import {take} from "rxjs";
import {LockingDeviceParamsDto} from "../../../../../shared/entities/locking-device/LockingDeviceParamsDto";
import {VARIANT} from "../../../../../shared/lookup/variant.lookup";
import {ChangeDetectorValue} from "../../../../../shared/util/change-detector/ChangeDetectorValue";
import {ChangeDetectorChanges} from "../../../../../shared/util/change-detector/ChangeDetectorChanges";
import {parseInt} from "lodash";
import {LockingDeviceParamsCdm} from "../../../../../shared/util/change-detector/models/locking-device-params.cdm";

@Component({
  selector: 'app-locking-device-params-form',
  templateUrl: './locking-device-params-form.component.html',
  styleUrls: ['./locking-device-params-form.component.scss']
})
export class LockingDeviceParamsFormComponent {
  @Output() onChanges = new EventEmitter<boolean>();
  @Input() accessReadonly = false;

  params: ChangeDetectorValue = new ChangeDetectorValue(undefined);
  //private _closeUuid: string = '0';
  //private _openUuid: string = '0';

  get isReadonly() {
    return this.accessReadonly || this.paramsManagedByGroup;
  }

  paramsManagedByGroup = false;
  paramsGroup?: { id: number, name: string };

  isOnlineAvailable = false;
  showLogging = false;
  timeModelsData: TimeModelDto[] = [];

  _selectedItem?: LockingDeviceParamsDto = undefined;

  @Input() set lockingDeviceParamsAndTimeModels(uuid: string) {
    this.apiService.timeModel.getAll().then(observer => {
      observer.pipe(take(1)).subscribe(timeModels => {
        this.patchTimeModelValues(timeModels);
        if (uuid == '') {
          return;
        }
        this.apiService.lockingDevice.getParameters(uuid)
          .then(item =>
            item.pipe(take(1)).subscribe(lockingDeviceDto =>
              this.patchParamValues(lockingDeviceDto)));
      });
    });
  }

  @Input() set lockingGroupDeviceParamsAndTimeModels(lockingDeviceParamsDto: LockingDeviceParamsDto | undefined) {
    this.apiService.timeModel.getAll().then(observer => {
      observer.pipe(take(1)).subscribe(timeModels => {
        this.patchTimeModelValues(timeModels);
        this.patchParamValues(lockingDeviceParamsDto);
      });
    });
  }

  private patchTimeModelValues(timeModels: TimeModelDto[]) {
    this.timeModelsData = [TimeModelDto.zeroTimeModelDtoWithName(this.translate.instant("LOCKING_DEVICES.DEVICES.FIELD.TIME_MODEL.NONE")),...timeModels];
  }

  private patchParamValues(dto: LockingDeviceParamsDto | undefined) {
    if (dto == null) {
      return;
    }
    let item: LockingDeviceParamsCdm = LockingDeviceParamsCdm.fromLockingDeviceParamsDto(dto) as LockingDeviceParamsCdm;
    item.officeTimeout = this.formatter.formatToNgbTimePicker(item.officeTimeout as number);
    this.params = new ChangeDetectorValue(item, () => { this.onChanges.emit(); }, item.validators());
    this.paramsManagedByGroup = item.paramsGroupId != null;
    if (this.paramsManagedByGroup) {
      this.paramsGroup = {
        id: item.paramsGroupId as number,
        name: item.paramsGroupName as string,
      };
    } else {
      this.paramsGroup = undefined;
    }
    this.showLogging = item.loggingId != null;
  }

  // Forms
  isLicenseBusiness: boolean;

  constructor(
    private apiService: ApiService,
    private formatter: FormatterService,
    private translate: TranslateService
  ) {
    this.isOnlineAvailable = apiService.lockingDevice.isOnlineAvailable()
    this.isLicenseBusiness = SessionManager.getInstance().isLicenseBusiness;
  }

  reset() {
    this.params.reset();
  }

  get changeDetectorChanges(): ChangeDetectorChanges {
    return new ChangeDetectorChanges(this.params);
  }

  getLockingDeviceParamsDto(): LockingDeviceParamsDto | undefined {
    return this.params.value ? (this.params.value as LockingDeviceParamsCdm).getLockingDeviceParamsDto() : undefined;
  }

  getGroupsLockingDeviceParamsDto(): LockingDeviceParamsDto {
    if (this._selectedItem === undefined) {
      this._selectedItem = LockingDeviceParamsDto.emptyLockingDeviceParamsDto();
    }
    return this.getLockingDeviceParamsDto()!;
  }

  get variantForTranslation(): string {
    return `LOCKING_DEVICES.DEVICES.VARIANT.${[...VARIANT.filter(value => `${this.params.value.variantId}` == `${value.id}`),VARIANT[0]][0].value.toUpperCase()}`;
  }

  timeModelName(uuid: string | undefined): string {
    return this.timeModelsData.find((t) => t.uuid === uuid)?.shortName ||
      this.translate.instant("LOCKING_DEVICES.DEVICES.FIELD.TIME_MODEL.NONE");
  }

  protected readonly VARIANT = VARIANT;
  protected readonly parseInt = parseInt;
}

// <a routerLink="/locking-devices/params-groups/{{paramsGroup.id}}"> {{paramsGroup.name}} </a>
