import {CESService} from "../../ces.service";
import {Router} from "@angular/router";
import { HttpClient } from "@angular/common/http";
import {NodeManager} from "../../auth-service/support-services/NodeManager";
import {firstValueFrom, Observable} from "rxjs";
import {UserDto} from "../../../../shared/entities/user/UserDto";
import {UserRequestDto} from "../../../../shared/entities/user/UserRequestDto";
import {SessionManager} from "../../auth-service/support-services/SessionManager";
import {ToastService} from "../../../../shared/notification/toast/toast.service";
import {UserMailVerificationDto} from "../../../../shared/entities/user/UserMailVerificationDto";
import {User} from "../../../../shared/entities/user/User";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class UserService extends CESService {
  public get currentUserImage(): string {
    if (SessionManager.getInstance().userImage != undefined) {
      return SessionManager.getInstance().userImage!;
    }

    const themeDesign = document.body.getAttribute("data-bs-theme");
    if (themeDesign != null && themeDesign.includes('black')) {
      return "assets/ces/user/user-black.svg"
    }
    return "assets/ces/user/user.svg"
  }

  constructor(router: Router,
              httpClient: HttpClient,
              notification: ToastService,
  ) {
    super(router, httpClient, notification)
  }

  // default CRUD
  async getAll(): Promise<Observable<User[]>> {
    return this.httpClient.get<User[]>(await this.buildUrl(undefined, '/users'));
  }

  // User

  async getCurrentUser(): Promise<Observable<UserDto>> {
    return (await this.getUser(""));
  }

  async getUser(uuid: string): Promise<Observable<UserDto>> {
    return this.httpClient.get<UserDto>(await this.buildUrl(undefined, `/user/${uuid}`));
  }

  async add(user: UserRequestDto): Promise<Observable<UserRequestDto>> {
    return this.httpClient.post<UserRequestDto>(await this.buildUrl(undefined, '/user'), user);
  }

  async update(user: UserRequestDto): Promise<Observable<UserRequestDto>> {
    return this.httpClient.put<UserRequestDto>(await this.buildUrl(undefined, `/user/${user.uuid}`), user);
  }

  async getMailVerification(userUuid: string): Promise<Observable<UserMailVerificationDto>> {
    return this.httpClient.get<UserMailVerificationDto>(await this.buildUrl(undefined, `/users/${userUuid}/mailVerification`));
  }

  async blockUnblock(userUuids: string[], block: boolean): Promise<boolean> {
    const users: {uuid: string[]} = {uuid: userUuids};
    return (await firstValueFrom(this.httpClient.patch<any>(await this.buildUrl(undefined, `/users/${block ? 'block' : 'unblock'}`), users, {observe: 'response'}))).ok;
  }

  async triggerMailVerification(userUuid: string): Promise<boolean> {
    return (await firstValueFrom(this.httpClient.patch<any>(await this.buildUrl(undefined, `/users/${userUuid}/mailVerification`),
      {observe: 'response'}))).ok;
  }

  async changePin(newPin: string): Promise<Observable<UserRequestDto>> {
    return this.httpClient.patch<UserRequestDto>(await this.buildUrl(undefined, '/user/changePin'), {
      pin: newPin,
      originClient: ''
    });
  }

  // USERS

  async userNameSuggestion(username: string): Promise<Observable<{ name: string, mailIsFree: boolean }>> {
    return this.httpClient.get<{name: string, mailIsFree: boolean}>(await this.buildUrl(undefined, `/users/userNameSuggestion?name=${username}`));
  }

  async delete(userUuid: string, silent = false): Promise<boolean> {
    return (await firstValueFrom(this.httpClient.delete<any>(await this.buildUrl(undefined, `/users/${userUuid}`), {observe: 'response'}))).ok;
  }

  // user image
  async uploadUserImage(userUuid: string, blob: any): Promise<boolean> {
    const formData: FormData = new FormData();
    formData.append('image', (blob as Blob), 'user.jpg');

    return (
      await firstValueFrom(
        this.httpClient.post<UserRequestDto>(
          await this.buildUrl(undefined, `/users/${userUuid}/image`),
          formData,
          {observe: 'response'})
      )
    ).ok;
  }

  async deleteUserImage(userUuid: string): Promise<boolean> {
    return (await firstValueFrom(this.httpClient.delete<any>(await this.buildUrl(undefined, `/users/${userUuid}/image`), {observe: 'response'}))).ok;
  }

  async getUserImage(userUuid: string): Promise<string> {
    let url: string = NodeManager.getInstance().getDataNodeUrl(`users/${userUuid}/image`);
    let arrayBuffer = (await firstValueFrom(this.httpClient.get(url, {
      observe: 'response',
      responseType: 'arraybuffer'
    })))?.body;
    return arrayBuffer == undefined ? '' : this._arrayBufferToBase64(new Uint8Array(arrayBuffer!));
  }

  private _arrayBufferToBase64(buffer: Uint8Array) {
    let binary: string = '';
    let bytes: Uint8Array = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  updateCurrentUserImage(imageData: string | undefined) {
    SessionManager.getInstance().userImage = imageData;
  }
}
