import {Subject} from "rxjs";
import {BindingProcess} from "../../../../shared/entities/IOB/BindingProcess";
import {BindingProcessError} from "../../../../shared/entities/IOB/BindingProcessError";
import {BindingState} from "../../../../shared/entities/IOB/ProcessData";
import {IoBridgeFlow} from "../../../enums/ioBridgeFlow";
import {IoBridgeProcess} from "../../../../shared/util/bindingStates";
import {LockingDeviceDto} from "../../../../shared/entities/locking-device/LockingDeviceDto";
import {LockingMediaDto} from "src/app/shared/entities/LockingMediaDto";

export class CommissionManager {
  private static _instance: CommissionManager;

  private flow: IoBridgeFlow = IoBridgeFlow.UNKNOWN;

  private _bindingProcess: BindingProcess;
  private _bindingProcessObservable: Subject<BindingProcess> = new Subject<BindingProcess>();

  private _bindingProcessState: BindingState;
  private _bindingProcessStateObservable: Subject<BindingState> = new Subject<BindingState>();

  private _bindingProcessError: BindingProcessError;
  private _bindingProcessErrorObservable: Subject<BindingProcessError> = new Subject<BindingProcessError>();

  private _loading: boolean;

  static getInstance(flow?: IoBridgeFlow): CommissionManager {
    if (this._instance == null) {
      this._instance = new CommissionManager();
    }
    if (flow != undefined){
        this._instance.flow = flow;
    }
    return this._instance;
  }

  private constructor() {
    this._loading = false;

    this._bindingProcess = BindingProcess.emptyBindingProcess();
    this._bindingProcessObservable.next(this._bindingProcess);

    this._bindingProcessState = BindingState.emptyBindingState();
    this._bindingProcessStateObservable.next(this._bindingProcessState);

    this._bindingProcessError = BindingProcessError.emptyBindingProcessError();
    this._bindingProcessErrorObservable.next(this._bindingProcessError);
  }

  reset() {
    this._bindingProcess = this.bindingProcess.resetBindingProcess();
    this._bindingProcessObservable.next(this._bindingProcess);

    this._bindingProcessState = new IoBridgeProcess().stateIdle;
    this._bindingProcessStateObservable.next(this._bindingProcessState);

    this._bindingProcessError = BindingProcessError.emptyBindingProcessError();
    this._bindingProcessErrorObservable.next(this._bindingProcessError);

    this.bindingProcess.flow = this.flow;
  }

  init(obj?: LockingDeviceDto | LockingMediaDto): CommissionManager {
    if (this.bindingProcessError.blockTillReset) {
        return this
    }
    this.reset()

    switch(this.flow) {
        case IoBridgeFlow.DEVICE_BIND:
            this.bindingProcess.serialNumber = ``;
            this.bindingProcess.objectName = ``;
            this.bindingProcess.objectUuid = ``;
            this.bindingProcess.deviceUid = ``;
            break;
        case IoBridgeFlow.DEVICE_REBIND:
            obj = obj as LockingDeviceDto;
            this.bindingProcess.serialNumber = ``;
            this.bindingProcess.objectName = obj!.shortName;
            this.bindingProcess.objectUuid = obj!.uuid;
            this.bindingProcess.deviceUid = `${obj!.deviceUid}`;
            break;
        case IoBridgeFlow.DEVICE_UNBIND:
            obj = obj as LockingDeviceDto;
            this.bindingProcess.serialNumber = `${obj!.serialNumber}`;
            this.bindingProcess.objectName = obj!.shortName;
            this.bindingProcess.objectUuid = obj!.uuid;
            this.bindingProcess.deviceUid = `${obj!.deviceUid}`;
            break;
        case IoBridgeFlow.MEDIA_BIND:
            this.bindingProcess.serialNumber = ``;
            this.bindingProcess.objectUuid = ``;
            break;
        case IoBridgeFlow.MEDIA_UNBIND:
            obj = obj as LockingMediaDto;
            this.bindingProcess.serialNumber = `${obj!.displayUid}`;
            this.bindingProcess.objectUuid = obj!.uuid;
            break;
        case IoBridgeFlow.UNKNOWN:
            //TODO
            break;
        default:
            //TODO
            break;
    }
    return this
  }

  // Getter & Setter

  get bindingProcess(): BindingProcess {
    return this._bindingProcess;
  }

  set bindingProcess(value: BindingProcess) {
    this._bindingProcess = value;
    this._bindingProcessObservable.next(this._bindingProcess);
  }

  get bindingProcessObservable(): Subject<BindingProcess> {
    return this._bindingProcessObservable;
  }

  set bindingProcessError(value: BindingProcessError) {
    this._bindingProcessError = value;
    this._bindingProcessErrorObservable.next(value);
  }

  get bindingProcessError(): BindingProcessError {
    return this._bindingProcessError;
  }

  get bindingProcessErrorObservable(): Subject<BindingProcessError> {
    return this._bindingProcessErrorObservable;
  }

  get bindingProcessState(): BindingState {
    return this._bindingProcessState;
  }

  set bindingProcessState(value: BindingState) {
    this._bindingProcessState = value;
    this.bindingProcessStateObservable.next(value);
  }

  get bindingProcessStateObservable(): Subject<BindingState> {
    return this._bindingProcessStateObservable;
  }

  get loading(): boolean {
    return this._loading;
  }

  set loading(value: boolean) {
    this._loading = value;
  }

  //
  get errorCode(): number | string {
    return this.bindingProcessError.code;
  }

  get errorIsPresent(): boolean {
    return this.bindingProcessError.blockTillReset;
  }

  get state(): string {
    return this.bindingProcessState.translationKey;
  }

  get error(): string {
    return this.bindingProcessError.error;
  }

}
