import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommissioningComponent } from '../../../../shared/commissioning/commissioning.component';
import { BehaviorSubject, debounceTime, Observable } from 'rxjs';
import { ModalCloseCause } from 'src/app/core/enums/modalCloseCause';
import { CommissionManager } from 'src/app/core/services/auth-service/support-services/CommissionManager';
import { IoBridgeService } from 'src/app/core/services/io-bridge-service/io-bridge.service';
import { BindingProcessError } from 'src/app/shared/entities/IOB/BindingProcessError';
import { isInteger } from 'lodash';
import { ItemAction } from 'src/app/shared/models/Actions';
import { IoBridgeFlow } from 'src/app/core/enums/ioBridgeFlow';

@Component({
  selector: 'app-locking-media-binding',
  templateUrl: './locking-media-binding.component.html',
  styleUrl: './locking-media-binding.component.scss'
})
export class LockingMediaBindingComponent implements OnInit, OnDestroy {
  @ViewChild(CommissioningComponent) Commissioning!: CommissioningComponent;

  private _commissionManager: CommissionManager = CommissionManager.getInstance(IoBridgeFlow.MEDIA_BIND);

  isLoading: boolean = false;
  globalBindingErrorProcess: BindingProcessError;

  providePin: string = '';
  provideSN: string = '';
  deviceName: string = '';
  processAndPollingRunning: boolean = false;

  headerText: string = 'PROGRAMMING.MODAL.LOCKING_MEDIA.ADD';
  buttonText: string = 'PROGRAMMING.MODAL.BUTTON.CLOSE_LIST';

  addRemoveButton: ItemAction = new ItemAction (
    'PROGRAMMING.MODAL.LOCKING_MEDIA.SEND_AND_BIND',
    (): void => {this.startProcessIOBridge()},
    false,
    'btn btn-outline-primary',
    ''
  )

  addedMedia: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  getAddedMedia(): Observable<boolean> {
    return this.addedMedia.asObservable()
  }

  constructor(
    private ioBridgeService: IoBridgeService,
  ){
    this.globalBindingErrorProcess = this.commissionManager.bindingProcessError;
    this.commissionManager.bindingProcessErrorObservable.pipe(debounceTime(500)).subscribe({
      next: value => this.globalBindingErrorProcess = value
    });
  }

  ngOnInit(): void {
    this.providePin = this.commissionManager.bindingProcess.pin;
    this.provideSN = this.commissionManager.bindingProcess.serialNumber;
    this.deviceName = this.commissionManager.bindingProcess.objectName;

    if (!this.globalBindingErrorProcess.blockTillReset) {
      this.isLoading = true;
      this.ioBridgeService.iobConnectionUpdater().then(() => {
        this.isLoading = false;
      });
    }
  }

  ngOnDestroy() {
    if (this.commissionManager.bindingProcessState.order == 0 && !this.globalBindingErrorProcess.blockTillReset) {
      this.commissionManager.reset();
    }
    this.ioBridgeService.iobConnectionUpdater();
  }

  async startProcessIOBridge() {
    this.Commissioning.startButtonClicked = true;
    this.bind()
    this.Commissioning.startButtonClicked = false;
  }

  private async bind() {
    await this.Commissioning.prepare();
    this.Commissioning.pollFetch().catch(() => this.processAndPollingRunning = false);
    const bind: Response = await this.ioBridgeService.fetchBindingMedia(this.format6Digit(this.provideSN));
    if (bind.ok) {
      let body: any = undefined;
      if (bind.status != 204 && bind.bodyUsed) {
        body = await bind.json();
      }
      await this.Commissioning.finishSuccessAndClose((body == undefined || body.uuid == undefined) ? (ModalCloseCause.SUCCESS || 3) : body);
    } else {
      await this.Commissioning.finishError(bind);
    }
    this.providePin = this.commissionManager.bindingProcess.pin
    this.provideSN = this.commissionManager.bindingProcess.serialNumber
    this.deviceName = this.commissionManager.bindingProcess.objectName
    this.addedMedia.next(true)
  }

  format6Digit(n: string): string {
    return n.padStart(6, '0')
  }


  //

  get commissionManager(): CommissionManager {
    return this._commissionManager;
  }

  get pinInputEnabled(): boolean {
    return !((this.provideSN.length < 1 || this.provideSN.length > 5) || this.commissionManager.bindingProcessState.order! != 0) && !this.commissionManager.errorIsPresent && /^\d+$/.test(this.provideSN);
  }

  get serialNumberInputEnabled(): boolean {
    return (this.Commissioning ? (!this.Commissioning.startButtonClicked && !this.isLoading) : false)
  }

}
