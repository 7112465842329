import {Component, OnInit, ViewChild} from '@angular/core';
import {ApiService} from "../../core/services/api-service/api.service";
import {PendingChangesBlocker} from "../../core/guards/pending-changes-view.guard";
import {ChangeDetectorForm} from "../../shared/util/change-detector/ChangeDetectorForm";
import {ItemListItem} from "../../shared/item-list/ItemListItem";
import {ItemSaveAction} from "../../shared/models/Actions";
import {SubmitUtils} from "../../shared/util/SubmitUtils";
import {SystemOverviewComponent} from "./overview/overview.component";
import {SystemSettingsComponent} from "./settings/settings.component";
import {SessionManager} from "../../core/services/auth-service/support-services/SessionManager";
import {ClientDto} from "../../shared/entities/client/ClientDto";
import {Subject, take} from "rxjs";
import {ItemManager} from "../../shared/item-list/ItemManager";
import {ItemListComponent} from "../../shared/item-list/item-list.component";
import {PagetitleAction} from "../../shared/pagetitle/pagetitle-action";
import {ToastService} from "../../shared/notification/toast/toast.service";
import {ModalService} from "../../shared/notification/modal/modal.service";
import {ChangeDetectorValue} from "../../shared/util/change-detector/ChangeDetectorValue";
import {cloneDeep} from "lodash";

enum SystemTabs {
  OVERVIEW = 'overview',
  SETTINGS = 'settings'
}

@Component({
  selector: 'app-system',
  templateUrl: './system.component.html',
  styleUrls: ['./system.component.scss']
})
export class SystemComponent implements OnInit, PendingChangesBlocker {
  _isLoading: boolean = true;

  @ViewChild(ItemListComponent) appItemList!: ItemListComponent<ClientDto>;
  @ViewChild(SystemOverviewComponent) systemOverviewComponent!: SystemOverviewComponent;
  @ViewChild(SystemSettingsComponent) systemSettingsComponent!: SystemSettingsComponent;

  changeDetector!: ChangeDetectorForm;

  // Items
  itemManager: ItemManager<ClientDto>
  itemSelected = false
  selectedItem: ClientDto = ClientDto.emptyClientDto();
  itemChangeValue: ChangeDetectorValue = new ChangeDetectorValue(this.selectedItem);

  $saveActionDisabled: Subject<boolean> = new Subject<boolean>();
  pageTitleSaveAction = new PagetitleAction("BUTTON.SAVE", "save-system-system", "btn-outline-primary", "mdi mdi-content-save-outline",
      false, this.$saveActionDisabled);

  selectedNavTab: SystemTabs = SystemTabs.OVERVIEW
  showLicenseExpiration = false

  validatorMaxLength64: number = 64;

  private sessionManager: SessionManager;

  constructor(private apiService: ApiService,
              private modalService: ModalService,
              private notification: ToastService) {
    this.itemManager = new ItemManager<ClientDto>(this, notification);
    this.sessionManager = SessionManager.getInstance();
    this.$saveActionDisabled.subscribe(() => {
      setTimeout(() => {
        let disabled: boolean = true;
        try {
          disabled = !this.itemChangeValue.isValid || !this.itemChangeValue.hasChanges;
        } catch (e) {}
        this.$saveActionDisabled.next(disabled);
      }, 666/2);
    });
    this.$saveActionDisabled.next(false);
  }

  mapToItemList(item: ClientDto): ItemListItem<ClientDto> {
    return new ItemListItem(item.uuid, item.systemId, item)
  }

  hasPendingChanges(): boolean {
    return this.itemChangeValue.hasChanges;
  }

  async ngOnInit() {
    this._isLoading = true;
    this.apiService.client.getClient().then(response => {
      response.pipe(take(1)).subscribe({
        next: result => {
          // Must be deep copy, otherwise systemData changes if selectedItem changes.
          this.sessionManager.setSystemData(cloneDeep(result));
          this.showLicenseExpiration = result.licenseExpireTimestamp != null &&
            result.licenseTypeId > 1;
        },
        complete: () => {
          // Must be deep copy, otherwise systemData changes if selectedItem changes.
          this.selectedItem = cloneDeep(this.sessionManager.getSystemData()!);
          this.itemManager.setItems([this.selectedItem]);
          this.appItemList.setSelectedListItem(this.mapToItemList(this.selectedItem), new ItemSaveAction());
          this._isLoading = false;
          this.createItemChangeValue();
        }
      });
    });
  }

  private createItemChangeValue() {
    this.itemChangeValue = new ChangeDetectorValue(cloneDeep(this.selectedItem), (input: boolean) => { },
      ChangeDetectorValue.mergeValidatorMaps(
        ChangeDetectorValue.createNumberValidatorMinMaxRequired('mobilePermissionDuration', 8, 48),
        ChangeDetectorValue.createNumberValidatorMinMaxRequired('mailDeleteAfterDays', 1, 5),
        ChangeDetectorValue.createNumberValidatorMinMaxRequired('jobsDeleteAfterDays', 1, 365),
        ChangeDetectorValue.createNumberValidatorMinMaxRequired('defaultOpeningDuration', 3, 180),
        ChangeDetectorValue.createNumberValidatorMinMaxRequired('clientName', 0, 64)
      ));
  }

  async onSubmit() {
    if (SubmitUtils.reflectCheck(
      this.notification,
      this.itemChangeValue.isValid) || !this.hasPendingChanges()) {
      return;
    }

    let newItem = this.itemChangeValue.value as ClientDto;
    (await this.apiService.client.updateClient(newItem)).subscribe({
      next: () => {
        this.notification.showSuccess('NOTIFICATION.TOAST.SUCCESS.SAVE');
        this.systemOverviewComponent?.reset();
        this.sessionManager.setSystemData(newItem);
        this.itemChangeValue.reset();
      },
      error: () => this.notification.showError('NOTIFICATION.TOAST.CES_ERROR.cesErrExecution')
    });
  }

  get userIsAllowed(): boolean {
    return this.sessionManager.isAdminOrHigher;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }
}
