import {ChangeDetectorModel, ChangeDetectorValue} from "../ChangeDetectorValue";
import {LockingMediaDto} from "../../../entities/LockingMediaDto";
import {EventEmitter, input} from "@angular/core";
import {FormatterService} from "../../../../core/services/formatter-service/formatter.service";
import {values} from "lodash";

export class NewPasswordCdm implements ChangeDetectorModel {
  viewablePasswordFields: string[] = ['oldPassword', 'confirmPassword', 'newPassword'];

  valueChange: EventEmitter<any>;

  private _oldPassword: string = '';
  private _newPassword: string = '';
  private _confirmPassword: string = '';

  constructor(private regexPassword?: RegExp | string) {
    this.valueChange = new EventEmitter<any>();
  }

  validators(): Map<string, (input: any) => boolean> {
    return new Map<string, (input: any) => boolean>()
      .set('newPasswordRequired', (input: any) => {
        return (input._newPassword || '').length > 0;
      }).set('confirmPasswordRequired', (input: any) => {
        return (input._confirmPassword || '').length > 0;
      }).set('oldPasswordRequired', (input: any) => {
        return (input._password || '').length > 0;
      }).set('newPasswordMustNotMatch', (input: any) => {
        return (input._password || '') != '' && (input._confirmPassword || '') != (input._password || '') && (input._newPassword || '') != (input._password || '');
      }).set('confirmPasswordMustMatch', (input: any) => {
        return (input._confirmPassword || '') == (input._newPassword || '');
      }).set('pattern', (input: any) => {
        if (this.regexPassword) {
          return (new RegExp(this.regexPassword)).test(input._newPassword || '');
        }
        return true;
      });
  }

  set values(values: any) {
    if (values && typeof values == 'object') {
      for (let key in values) {
        const newVal = this.hasProp(values, key) ? values[key] : this.hasProp(this, `_${key}`) ? values[`_${key}`] : undefined;
        //@ts-ignore
        this.hasProp(this, key) ? this[key] = newVal : this.hasProp(this, `_${key}`) ? this[`_${key}`] = newVal : () => {
        };
      }
    }
  }

  private hasProp(thisArg: any, args_0: string): boolean {
    return Object.prototype.hasOwnProperty.call(thisArg, args_0);
  }

  get oldPassword(): string {
    return this._oldPassword;
  }

  set oldPassword(value: string) {
    this._oldPassword = value;
    this.valueChange.emit(true);
  }

  get newPassword(): string {
    return this._newPassword;
  }

  set newPassword(value: string) {
    this._newPassword = value;
    this.valueChange.emit(true);
  }

  get confirmPassword(): string {
    return this._confirmPassword;
  }

  set confirmPassword(value: string) {
    this._confirmPassword = value;
    this.valueChange.emit(true);
  }


}
