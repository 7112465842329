import {Component, Input} from '@angular/core';
import {ClientDto} from "../../../shared/entities/client/ClientDto";
import {FormatterService} from "../../../core/services/formatter-service/formatter.service";
import {TranslateService} from "@ngx-translate/core";
import {LICENSE} from "../../../shared/lookup/license.lookup";
import {ChangeDetectorValue} from "../../../shared/util/change-detector/ChangeDetectorValue";
import {parseInt} from "lodash";

@Component({
  selector: 'app-system-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class SystemOverviewComponent {
  validatorMaxLength64: number = 64;
  licenseExpiration: { year: number, month: number, day: number } = {year: 0, month: 0, day: 0};
  timezoneId: ChangeDetectorValue = new ChangeDetectorValue(0, () => { });

  @Input() accessReadonly: boolean = true;
  @Input() system: ChangeDetectorValue = new ChangeDetectorValue(ClientDto.emptyClientDto());
  @Input() set timezone(timezoneId: number) {
    this.timezoneId = new ChangeDetectorValue((timezoneId || 0), () => {
      this.system.value.timezoneId = this.timezoneId.value;
    });
    if (this.system.value.licenseExpireTimestamp && parseInt(this.system.value.licenseTypeId) >= 2) {
      this.licenseExpiration = this.formatter.formatToNgbDatePicker(parseInt(this.system.value.licenseExpireTimestamp));
    }
  }

  protected get licenseTypeId(): string {
    return this.translate.instant(`SYSTEM.LICENSE.${[...LICENSE.filter(value => value.id == (this.system.value.licenseTypeId || 0)), LICENSE[0]][0].value.toUpperCase()}`);
  }

  protected get lastLoginTimestamp(): string {
    return this.formatter.formatTimestamp(
      this.system.value.lastLoginTimestamp || 0,
      {mode: 'dateTime', replaceInvalid: this.translate.instant("SYSTEM.LAST_LOGIN.NO_DATE")});
  }

  protected get lastChangeTimestamp(): string {
    return this.formatter.formatTimestamp(
      this.system.value.lastChangeTimestamp || 0,
      {mode: 'dateTime', replaceInvalid: this.translate.instant("SYSTEM.LAST_LOGIN.NO_DATE")});
  }

  get progressbarClass(): string {
    const val: number = (this.system.value.idCount / this.system.value.licenseIdCount);
    if (val < 0.7) {
      return 'primary';
    } else if (val < 0.9) {
      return 'warning';
    }
    return 'danger';
  }

  constructor(private translate: TranslateService,
              private formatter: FormatterService) {
  }


  reset() {
    this.timezoneId.reset();
  }

  protected readonly parseInt = parseInt;
}
