import {BehaviorSubject, Observable, Subject} from "rxjs";
import {ItemListItem, ItemListMapper} from "./ItemListItem";
import {LogbookDto} from "../entities/Logbook/LogbookDto";
import {ToastService} from "../notification/toast/toast.service";

export class ItemManager<T> {

  items: Map<string, ItemListItem<T>>
  private changeEmitter: Subject<Map<string, ItemListItem<T>>>

  get onChanges(): Observable<Map<string, ItemListItem<T>>> {
    return this.changeEmitter.asObservable()
  }

  constructor(private itemListMapper: ItemListMapper<T>,
              private notification?: ToastService,
              private sortItems?: any) {
    this.items = new Map<string, ItemListItem<T>>()
    this.changeEmitter = new BehaviorSubject<Map<string, ItemListItem<T>>>(this.items)
  }

  emitChange() {
    if(this.sortItems){
        this.items = this.sortItems(this.items)
    }

    this.changeEmitter.next(this.items)
  }

  setItems(items: T[]) {
    this.items = new Map(items.map(item => {
      const mappedItem = this.itemListMapper.mapToItemList(item)
      return [mappedItem.uuid, mappedItem]
    }))
    this.emitChange()
  }

  addItems(...items: T[]) {
    items.forEach(item => {
      const mappedItem = this.itemListMapper.mapToItemList(item);
      if (items[0] instanceof LogbookDto) {
        //@ts-ignore
        this.items.set(mappedItem.uuid, item.title, mappedItem);
      } else {
        this.items.set(mappedItem.uuid, mappedItem);
      }
    })

    this.emitChange()
    this.notification?.showSuccess(`NOTIFICATION.TOAST.SUCCESS.SAVE`);
  }

  updateItem(item: T, upsert = false) {
    const mappedItem = this.itemListMapper.mapToItemList(item)
    if (!this.items.has(mappedItem.uuid)) {
      return upsert ? this.addItems(item) : {}
    }

    this.items.set(mappedItem.uuid, mappedItem)

    this.emitChange()
    this.notification?.showSuccess(`NOTIFICATION.TOAST.SUCCESS.UPDATE`);
  }

  removeItems(...items: T[]) {
    const deleteIds = items.map(item => this.itemListMapper.mapToItemList(item).uuid)
    this.removeItemsById(...deleteIds)
  }

  removeItemsById(...ids: string[]) {
    ids.forEach(id => this.items.delete(id))

    this.emitChange()
    this.notification?.showSuccess(`NOTIFICATION.TOAST.SUCCESS.DELETE`);
  }

  clear() {
    this.items.clear()
    this.emitChange()
  }

  get length(): number {
    return this.items.size
  }

  forEach(forEach: (item: ItemListItem<T>) => void) {
    this.items.forEach(forEach)
  }
}
